import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./styles.css";
import { Button, Table, DatePicker, Tag, Tooltip } from "antd";
import {
  EyeOutlined,
  PlusOutlined,
  SendOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import dayjs from "dayjs";
import "dayjs/locale/pt-br"; // Importa o locale para português do Brasil
import locale from "antd/es/date-picker/locale/pt_BR"; // Importa o locale do Ant Design para português do Brasil
import moment from "moment";
import EditTemplate from "../EditTemplate/EditTemplate";
import NewUser from "../../../../components/NewUser/NewUser";

const ViewQuestionarios = () => {
  const {
    postVersaoRespostas,
    moduloMapeamento,
    windowWidth,
    pageSizeBig,
    currentCliente,
    atualizaPrazoVersaoRespostas,
    userInfo,
    usuarios,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const navigate = useNavigate();
  const params = useParams();

  const [editingKey, setEditingKey] = useState("");
  const [showModalNewUser, setShowModalNewUser] = useState(false);

  const handleDateChange = (date: any, record: any) => {
    atualizaPrazoVersaoRespostas(record._id, date);

    setEditingKey("");
  };
  const novoQuestionario = async () => {
    params.qid && postVersaoRespostas(params.qid);
  };

  const columns = [
    {
      title: "Data de criação",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: any) => moment(new Date(date)).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Data de envio",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date: any, record: any) =>
        record.created_at !== date
          ? moment(new Date(date)).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      title: "Prazo",
      dataIndex: "prazo",
      key: "prazo",
      render: (prazo: any, record: any) => {
        const isEditing = record.key === editingKey;

        if (
          userInfo?.grupos?.find(
            (gp: any) => gp.name !== "assinante" && gp.name !== "seusdados"
          )
        ) {
          return prazo !== null
            ? moment(new Date(prazo)).format("DD/MM/YYYY")
            : "-";
        }
        return isEditing ? (
          <DatePicker
            locale={locale}
            defaultValue={dayjs(new Date(prazo))}
            onChange={(date) => handleDateChange(date, record)}
          />
        ) : (
          <div>
            {prazo !== null
              ? moment(new Date(prazo)).format("DD/MM/YYYY")
              : "-"}
            <Button type="link" onClick={() => setEditingKey(record.key)}>
              Editar
            </Button>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: any) => {
        let iconColor = "";
        let text = "";
        if (!status || status === "Pendente") {
          iconColor = "#b5b5b5";
        } else if (
          status === "Aguardando respostas" ||
          status === "Concluído"
        ) {
          iconColor = "green";
        } else if (status === "Enviado") {
          iconColor = "#fcba03";
        }

        if (!status || status === "Pendente") {
          text = "Não Enviado";
        } else if (status === "Concluído") {
          text = "Concluído";
        } else if (status === "Enviado") {
          text = "Enviado";
        }

        if (
          record.respostas ===
          moduloMapeamento.questionarios?.filter(
            (quest: any) => quest._id.toString() === params.qid
          )[0]?.qtd_respondentes
        ) {
          text = "Concluído";
          iconColor = "green";
        }
        return (
          <Tag color={iconColor} style={{ fontWeight: "bold" }}>
            {text}
          </Tag>
        );
      },
    },

    {
      title: "Respostas",
      dataIndex: "respostas",
      key: "respostas",
      render: (respostas: number) => {
        return (
          <Button type="link">
            {respostas} /{" "}
            {moduloMapeamento.questionarios?.filter(
              (quest: any) => quest._id.toString() === params.qid
            )[0]?.qtd_respondentes || 0}
          </Button>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "_id",
      key: "_id",
      render: (id: String, record: any) => {
        const isDisabled = userInfo?.grupos?.find(
          (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
        )
          ? false
          : !(record.respostas && record.respostas > 0);

        return (
          <div>
            {/* {record.status !== "respondido" && ( */}
            <Button
              type="primary"
              onClick={() => moduloMapeamento.sendQuestionarios(record)}
              icon={<SendOutlined />}
              style={{ marginRight: "1rem" }}
            >
              {record.status === "Enviado" ? "Enviar novamente" : "Enviar"}
            </Button>
            {/* )} */}
            {/* {record.status === "respondido" && ( */}
            <>
              <Tooltip
                title={
                  isDisabled
                    ? "Você precisa ter mais de uma resposta para visualizar a análise."
                    : ""
                }
              >
                <Button
                  disabled={isDisabled}
                  type="default"
                  className="btn-success"
                  htmlType="submit"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    navigate(`/analise/${params.qid}/versao/${id}`);
                  }}
                >
                  Análise
                </Button>
              </Tooltip>
            </>
            {/* )} */}
          </div>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      title: "Data de criação",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: any) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>{moment(new Date(date)).format("DD/MM/YYYY")}</p>
          <p>{moment(new Date(date)).format("HH:mm")}</p>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let iconColor = "";
        let text = "";
        if (!status) {
          iconColor = "#b5b5b5";
        } else if (status === "respondido") {
          iconColor = "green";
        } else if (status === "enviado") {
          iconColor = "#fcba03";
        }

        if (!status) {
          text = "Não Enviado";
        } else if (status === "respondido") {
          text = "Questionário respondido";
        } else if (status === "enviado") {
          text = "Enviado";
        }

        return (
          <Tag color={iconColor} style={{ fontWeight: "bold" }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "_id",
      key: "_id",
      render: (id: String, record: any) => (
        <div>
          {/* {record.status !== "respondido" && ( */}
          <Button
            type="primary"
            onClick={() => moduloMapeamento.sendQuestionarios(record)}
            icon={<SendOutlined />}
            style={{ width: "6rem" }}
          >
            {record.status === "enviado" ? "Enviar novamente" : "Enviar"}
          </Button>

          <Button
            type="default"
            className="btn-success"
            style={{ width: "6rem", marginTop: "1rem" }}
            htmlType="submit"
            icon={<EyeOutlined />}
            onClick={() => {
              navigate(`/analise/${params.qid}/versao/${id}`);
            }}
          >
            Análise
          </Button>
        </div>
      ),
    },
  ];

  const columnsUsers = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
  ];

  const steps = [
    {
      title: "Adicionar respondentes",
      content: (
        <>
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            style={{ marginBottom: "1rem" }}
            onClick={() => setShowModalNewUser(true)}
          >
            Novo usuário
          </Button>
          <Table
            dataSource={usuarios
              ?.filter((u: any) => !u.inativo)
              ?.filter((u: any) =>
                params?.id || currentCliente?._id
                  ? u.cliente_id === params?.id ||
                    u.cliente_id === currentCliente?._id
                  : u.cliente_id
              )}
            size="small"
            columns={columnsUsers}
          />
          <NewUser
            showModal={showModalNewUser}
            setShowModal={setShowModalNewUser}
          />
        </>
      ),
    },
    {
      title: "Vincular respondentes",
      content: <EditTemplate mode="delegar" />,
    },
    {
      title: "Aplicação do questionário",
      content: (
        <>
          <div className="clientes__actions">
            {userInfo?.grupos?.find(
              (gp: any) =>
                gp.name === "assinante" ||
                gp.name === "seusdados" ||
                gp.name === "sponsor"
            ) && (
              <Button
                className="clientes__actions--new"
                type="primary"
                onClick={() => novoQuestionario()}
                icon={<PlusOutlined />}
              >
                Nova aplicação
              </Button>
            )}
          </div>
          <Table
            dataSource={
              moduloMapeamento.questionarios.filter(
                (quest: any) =>
                  quest._id.toString() === params.qid && quest.versoes[0]
              )[0] &&
              moduloMapeamento.questionarios.filter(
                (quest: any) =>
                  quest._id.toString() === params.qid && quest.versoes[0]
              )[0].versoes &&
              moduloMapeamento.questionarios
                .filter(
                  (quest: any) =>
                    quest._id.toString() === params.qid && quest.versoes[0]
                )[0]
                .versoes.sort(
                  (a: any, b: any) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
            }
            pagination={{ pageSize: pageSizeBig }}
            columns={windowWidth > 800 ? columns : columnsMobile}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    !currentCliente && navigate("/dashboardinicial");
  }, [currentCliente]);

  return (
    <div className="viewQuestionario">
      <BackHeader
        title={
          moduloMapeamento.questionarios?.filter(
            (q: any) => q._id === params.qid
          )[0]?.nome || ""
        }
        prev
      />
      <div className="viewQuestionario_content">
        {steps.map((step: any, index: number) => (
          <>
            <div className="step_custom">{index + 1}</div>
            <h3 className="step_title_custom">{step.title}</h3>
            <div
              className={
                index === steps.length - 1
                  ? "step_content_custom--noborder"
                  : "step_content_custom"
              }
            >
              {step.content}
            </div>
          </>
        ))}
        {/* <Steps onChange={onChange} current={current} items={items} />

        <div style={{ marginTop: "1rem" }}>{steps[current].content}</div>

        <div style={{ marginTop: "1rem" }}>
          {current > 0 && (
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => prev()}
              icon={<ArrowLeftOutlined />}
            >
              Anterior
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              icon={<ArrowRightOutlined />}
            >
              Próximo
            </Button>
          )}
        </div> */}
      </div>
      {/* <Collapse
        style={{
          marginTop: "1rem",
          marginBottom: "2rem",
          // background: "#f5f5fb",
        }}
        className="task-collapse"
        items={[
          {
            key: "1",
            label: "Plano de ação - Tarefas pendentes",
            children: <TaskList horizontal noHeader />,
          },
        ]}
      /> */}
    </div>
  );
};

export default ViewQuestionarios;
