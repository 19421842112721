import {
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import MarkdownPreview from "@uiw/react-markdown-preview";

import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  List,
  message,
  Row,
  Space,
  Spin,
  Tag,
  theme,
  Tooltip,
} from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContextType } from "../../../../../@types/global";
import { GlobalContext } from "../../../../../context/globalContext";
import BackHeader from "../../../../BackHeader/BackHeader";
import { InfoCircle } from "iconsax-react";
import Task from "../../../../Tarefas/Task/Task";
import Comments from "../../../../Comments/Comments";
import CriarTarefa from "../../../../Tarefas/CriarTarefa/CriarTarefa";
import Search from "antd/es/input/Search";
import { Reuniao } from "../types";
import moment from "moment";
const { useToken } = theme;

const { Panel } = Collapse;

type Props = {
  data_prazo: Date;
  currentReuniaoId: string;
  reunioes: Reuniao[];
};

const disabledDate = (current: any) => {
  return current && current < moment().startOf("day");
};

const truncateDescription = (description: any, maxLength = 15) => {
  if (description?.length > maxLength) {
    return description.slice(0, maxLength) + "...";
  }
  return description;
};

const MembrosPresentes: React.FC<{
  cliente_id: string;
  cppdId: string;
  reuniao: Reuniao;
}> = ({ cliente_id, cppdId, reuniao }) => {
  const [selectedMembros, setSelectedMembros] = useState<CheckboxValueType[]>(
    []
  );

  const navigate = useNavigate();

  const [membros, setMembros] = useState<any[]>([]);
  const { usuarios, atualizarReuniaoPorId, currentCliente } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const [loading, setLoading] = useState(true);
  const [disabledSelectMembros, setDisabledSelectMembros] = useState(false);

  useEffect(() => {
    if (usuarios && usuarios.length > 0) {
      // Verifica se `usuarios` está carregado

      const gruposDoComite = [
        "65f1a790ff2de399bce35a8d",
        "66aa6aa01386fb3c3b13bafd",
        "66c61fe39810d75998778418",
        "66c61ff79810d75998778419",
      ];

      const usuariosComite = usuarios?.filter(
        (usr: any) =>
          usr.cliente_id === cliente_id &&
          usr.grupos
            ?.map((g: any) => g._id)
            .some((grupo: string) => gruposDoComite.includes(grupo))
      );

      setMembros(usuariosComite);
      setLoading(false); // Parar o spin quando os membros são carregados
    } else {
      console.error("Usuarios ainda não estão disponíveis");
    }
  }, [cliente_id, usuarios]);

  useEffect(() => {
    if (reuniao?.membrosPresentes?.[0]) {
      const ids = reuniao.membrosPresentes;
      setSelectedMembros(ids);
      setDisabledSelectMembros(true);
    }
  }, [reuniao.membrosPresentes]);

  useEffect(() => {
    !currentCliente?._id && navigate("/cppd");
  }, []);

  const handleUpdateMembros = async () => {
    try {
      // Filtrar os membros selecionados
      const membrosSelecionados = usuarios
        ?.filter((membro: any) => selectedMembros.includes(membro._id))
        .map((membro: any) => membro._id);

      if (membrosSelecionados.length === 0) {
        message.warning("Nenhum membro foi selecionado.");
        return;
      }

      // Enviar o patch request com os membros presentes
      const response = await atualizarReuniaoPorId(cppdId, reuniao._id, {
        membrosPresentes: membrosSelecionados,
      });

      // Verifique se a resposta contém dados válidos
      if (response && response.membrosPresentes) {
        message.success("Membros presentes atualizados com sucesso!");
      } else {
        throw new Error("Resposta inesperada do servidor.");
      }
    } catch (error) {
      // Captura e exibe o erro
      console.error("Erro ao atualizar os membros presentes:", error);
      message.error("Erro ao atualizar os membros presentes.");
    }
  };

  return (
    <div className="mb-6">
      <BackHeader title="Dia da reunião" prev />

      <h3 className="text-lg font-semibold mb-4">1. Membros presentes</h3>
      {loading ? (
        <Spin /> // Exibe o spin enquanto os membros estão carregando
      ) : (
        <>
          <div>
            <Checkbox.Group
              onChange={setSelectedMembros}
              value={selectedMembros}
              className="space-y-2"
              disabled={disabledSelectMembros}
            >
              <Space direction="vertical">
                {[...membros].map((membro) => (
                  <Checkbox key={membro._id} value={membro._id}>
                    {membro.name}{" "}
                    {membro?.grupos?.map((gp: any) => (
                      <Tag
                        color={
                          gp.name === "assinante" || gp.name === "seusdados"
                            ? "purple"
                            : "blue"
                        }
                        className="Header__right--nivel"
                      >
                        {gp.name
                          .replace("assinante", "consultor")
                          .toUpperCase()}
                      </Tag>
                    ))}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </div>
          {!disabledSelectMembros && (
            <Button
              type="primary"
              className="mt-6"
              icon={<SaveOutlined />}
              onClick={handleUpdateMembros}
            >
              Salvar
            </Button>
          )}
        </>
      )}
      <div></div>
      <br />
      <h3 className="text-lg font-semibold mb-4">
        2. Representante do(a) DPO - Encarregado pelo tratamento de dados
      </h3>
      {loading ? (
        <Spin /> // Exibe o spin enquanto os membros estão carregando
      ) : (
        <>
          <div>
            <Checkbox.Group
              onChange={setSelectedMembros}
              value={selectedMembros}
              className="space-y-2"
              disabled={disabledSelectMembros}
            >
              <Space direction="vertical">
                {[
                  ...usuarios?.filter(
                    (usr: any) =>
                      (usr.grupos.find((gp: any) => gp.name === "assinante") ||
                        usr.grupos.find(
                          (gp: any) => gp.name === "seusdados"
                        )) &&
                      usr.cliente_id === "64e648ff902fd5e820397ebb" && // somente da Seusdados
                      ![
                        "65eb5dc5ff2de399bce35a47",
                        "64ee4407695c6fa68b3df907",
                      ].includes(usr._id) // não exibir usuários Vinícius
                  ),
                ].map((membro) => (
                  <Checkbox key={membro._id} value={membro._id}>
                    {membro.name}{" "}
                    {membro?.grupos?.map((gp: any) => (
                      <Tag
                        color={
                          gp.name === "assinante" || gp.name === "seusdados"
                            ? "purple"
                            : "blue"
                        }
                        className="Header__right--nivel"
                      >
                        {gp.name
                          .replace("assinante", "consultor")
                          .replace("seusdados", "consultor")
                          .toUpperCase()}
                      </Tag>
                    ))}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </div>
          {!disabledSelectMembros && (
            <Button
              type="primary"
              className="mt-6"
              icon={<SaveOutlined />}
              onClick={handleUpdateMembros}
            >
              Salvar
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const FollowUpModal: React.FC<{
  reuniao: Reuniao;
  cliente_id: string;
}> = ({ reuniao, cliente_id }) => {
  const { moduloTask, usuarios, obterReunioesPorCppd } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const [isVerPautaModalVisible, setIsVerPautaModalVisible] =
    useState<boolean>(false);
  const [reunioes, setReunioes] = useState<Reuniao[] | null>(null);

  const gruposDoComite = [
    "65f1a790ff2de399bce35a8d",
    "66aa6aa01386fb3c3b13bafd",
    "66c61fe39810d75998778418",
    "66c61ff79810d75998778419",
  ];

  const usuariosComite = usuarios
    ?.filter(
      (usr: any) =>
        usr.cliente_id === cliente_id &&
        usr.grupos
          ?.map((g: any) => g._id)
          .some((grupo: string) => gruposDoComite.includes(grupo))
    )
    .map((u: any) => u._id);

  useEffect(() => {
    const fetchReunioes = async () => {
      try {
        if (reuniao.cppd_id) {
          const response = await obterReunioesPorCppd(reuniao.cppd_id);
          setReunioes(response.reunioes);
        }
      } catch (error) {
        console.error("Erro ao obter reuniões:", error);
      }
    };

    fetchReunioes();
  }, [reuniao]);

  const verificarReuniaoProxima = ({
    data_prazo,
    currentReuniaoId,
    reunioes,
  }: Props): boolean => {
    // Filtrar reuniões com data_agendamento maior ou igual à data_prazo
    const reunioesValidas = reunioes.filter(
      (reuniao) => data_prazo <= new Date(reuniao.data_agendamento)
    );

    // Se não houver reuniões válidas, retorna false
    if (reunioesValidas.length === 0) {
      return false;
    }

    // Encontrar a reunião mais próxima da data_prazo
    const reuniaoProxima = reunioesValidas.reduce((prev, curr) => {
      const prevDate = new Date(prev.data_agendamento);
      const currDate = new Date(curr.data_agendamento);

      return currDate < prevDate ? curr : prev;
    });

    // Verificar se o _id da reunião mais próxima é igual ao currentReuniaoId
    return reuniaoProxima._id === currentReuniaoId;
  };

  return (
    <div className="mb-6">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3 className="text-lg font-semibold">
          3. Acompanhamento de tarefas (Vencidas ou próximas de vencer)
        </h3>

        <Tooltip title="Aguardando resolução">
          <InfoCircle
            size={18}
            style={{ marginLeft: "0.5rem", cursor: "pointer" }}
          />
        </Tooltip>
      </div>
      <br />
      {reunioes ? (
        moduloTask?.tasksCliente?.filter(
          (Task) =>
            Task.tipo !== "pauta" &&
            Task.status !== "Concluída" &&
            (usuariosComite?.includes(Task.owner_id) ||
              usuariosComite?.includes(Task.assignment_id)) &&
            Task.prazo_conclusao &&
            verificarReuniaoProxima({
              data_prazo: new Date(Task.prazo_conclusao),
              currentReuniaoId: reuniao._id,
              reunioes: reunioes,
            })
        )?.[0] ? (
          <List
            dataSource={moduloTask?.tasksCliente
              ?.filter(
                (Task) =>
                  Task.tipo !== "pauta" &&
                  Task.status !== "Concluída" &&
                  (usuariosComite?.includes(Task.owner_id) ||
                    usuariosComite?.includes(Task.assignment_id)) &&
                  Task.prazo_conclusao &&
                  verificarReuniaoProxima({
                    data_prazo: new Date(Task.prazo_conclusao),
                    currentReuniaoId: reuniao._id,
                    reunioes: reunioes,
                  })
                // reunioes com data_prazo mais proximo da reuniao desde que seja menor que a data de agendamento
              )
              ?.reduce((accumulator: any[], task: any) => {
                if (
                  !accumulator.some(
                    (existingTask) => existingTask._id === task._id
                  )
                ) {
                  accumulator.push(task);
                }
                return accumulator;
              }, [])}
            renderItem={(item) => (
              <div className="microTask_container" style={{ marginLeft: 0 }}>
                <Tooltip title="Ver item">
                  <EyeOutlined
                    style={{
                      cursor: "pointer",
                      marginRight: "0.5rem",
                      color: "#1677ff",
                    }}
                    onClick={() => {
                      moduloTask?.setSelectedTask(item);
                      setIsVerPautaModalVisible(true);
                    }}
                  />
                </Tooltip>
                {item.title}
              </div>
            )}
          />
        ) : (
          <Alert type="info" message="Nenhum item encontrado" showIcon />
        )
      ) : (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
      )}

      <Task
        setShowModal={setIsVerPautaModalVisible}
        showModal={isVerPautaModalVisible}
      />
    </div>
  );
};

const TarefasReuniao: React.FC<{
  reuniao: Reuniao;
  cliente_id: string;
}> = ({ reuniao, cliente_id }) => {
  const [isVerPautaModalVisible, setIsVerPautaModalVisible] =
    useState<boolean>(false);

  return (
    <div className="mb-6">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3 className="text-lg font-semibold">
          5. Tarefas distribuídas na reunião
        </h3>

        <Tooltip title="Tarefas exclusivamente relacionadas a esta reunião.">
          <InfoCircle
            size={18}
            style={{ marginLeft: "0.5rem", cursor: "pointer" }}
          />
        </Tooltip>
      </div>
      <br />
      <DefinirTarefas cliente_id={cliente_id} reuniao={reuniao} />
      <Task
        setShowModal={setIsVerPautaModalVisible}
        showModal={isVerPautaModalVisible}
      />
    </div>
  );
};

const Deliberacoes: React.FC<{
  reuniao: Reuniao;
  cliente_id: string;
}> = ({ reuniao, cliente_id }) => {
  const { moduloTask } = useContext(GlobalContext) as GlobalContextType;

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-4">4. Ordem do dia</h3>
      <Collapse className="border rounded-lg">
        {moduloTask?.tasksCliente
          ?.filter(
            (Task) =>
              Task.tipo === "pauta" && Task.modelo_referencia_id === reuniao._id
          )
          ?.map((pauta: any, index: number) => {
            return (
              <Panel
                header={
                  <>
                    <b>{index + 1}. </b>
                    {pauta.title}
                  </>
                }
                key={pauta._id}
              >
                {pauta?.description?.length > 1 && (
                  <>
                    <h5>Descrição da pauta</h5>
                    <br />
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: pauta.description,
                      }}
                    />

                    <br />
                    <br />
                  </>
                )}

                <h5 style={{ marginBottom: "-2.5rem" }}>Deliberações</h5>
                <Comments tipo="pauta" task_id={pauta._id} />
                <br />
                <h5>Tarefas</h5>

                <DefinirTarefas
                  reuniao={reuniao}
                  cliente_id={cliente_id}
                  pautaId={pauta._id}
                />
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

const DefinirTarefas: React.FC<{
  reuniao: Reuniao;
  cliente_id: string;
  pautaId?: string;
}> = ({ reuniao, cliente_id, pautaId }) => {
  const { moduloTask, usuarios } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const [isVerTarefaModalVisible, setIsVerTarefaModalVisible] =
    useState<boolean>(false);
  const [criarTarefaModalVisible, setCriarTarefaModalVisible] =
    useState<boolean>(false);

  const handleShowModalCriar = () => {
    setCriarTarefaModalVisible(!criarTarefaModalVisible);
  };

  const [searchValue, setSearchValue] = useState("");
  const [filteredUsuarios, setFilteredUsuarios] = useState([]);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTaskUpdate, setLoadingTaskUpdate] = useState(false);

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (usuarios) {
      setFilteredUsuarios(
        usuarios.filter((usr: any) =>
          usr.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue, usuarios]);

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: "300px",
    maxHeight: "800px",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  let tarefasParaExibir: any[] = [];

  if (pautaId) {
    tarefasParaExibir = moduloTask?.tasksCliente
      ?.filter((Task: any) =>
        moduloTask?.tasksCliente
          ?.filter(
            (Task: any) =>
              Task.tipo === "pauta" && Task.modelo_referencia_id === reuniao._id
          )
          ?.map((task) => task._id)
          ?.find((id: string) => id === Task.dependencia)
      )
      ?.filter((task: any) => task.dependencia === pautaId)
      ?.reduce((accumulator: any[], task: any) => {
        if (
          !accumulator.some((existingTask) => existingTask._id === task._id)
        ) {
          accumulator.push(task);
        }
        return accumulator;
      }, []);
  } else {
    tarefasParaExibir = moduloTask?.tasksCliente
      ?.filter(
        (Task: any) =>
          Task.tipo === "reuniao" && Task.modelo_referencia_id === reuniao._id
      )
      ?.reduce((accumulator: any[], task: any) => {
        if (
          !accumulator.some((existingTask) => existingTask._id === task._id)
        ) {
          accumulator.push(task);
        }
        return accumulator;
      }, []);
  }

  return (
    <div className="mb-6">
      {tarefasParaExibir?.map((task: any, index: number) => {
        return (
          <Row className="microtask_container" style={{ marginLeft: "0rem" }}>
            <Col span={15} className="d-flex">
              <Tooltip title="Ver tarefa">
                <EyeOutlined
                  onClick={() => {
                    moduloTask?.setSelectedTask(task);
                    setIsVerTarefaModalVisible(true);
                  }}
                  style={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    color: "#1677ff",
                  }}
                />
              </Tooltip>

              <p style={{ maxWidth: "600px" }}>
                <b>{index + 1}.</b> {task.title}
              </p>
            </Col>
            <Col span={4}>
              {task.prazo_conclusao ? (
                <p>
                  Prazo:{" "}
                  <span style={{ color: "#1677ff" }}>
                    {moment(new Date(task.prazo_conclusao)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </p>
              ) : (
                <p>
                  Prazo:{" "}
                  {loadingTaskUpdate ? (
                    <Spin size="small" />
                  ) : (
                    <DatePicker
                      size="small"
                      // format="DD/MM/YYYY"
                      onChange={(prazo) => {
                        setLoadingTaskUpdate(true);
                        moduloTask
                          .updateTask({ ...task, prazo_conclusao: prazo })
                          .finally(() => setLoadingTaskUpdate(false));
                      }}
                      disabledDate={disabledDate}
                      placeholder="Definir prazo"
                    />
                  )}
                </p>
              )}
            </Col>
            <Tooltip title="Alterar atribuíção">
              <Col
                span={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {loading && (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 16 }} spin />
                    }
                  />
                )}
                <Dropdown
                  placement="bottom"
                  disabled={loading}
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "1",
                        type: "group",
                        label: "Sponsors",
                        children: usuarios
                          ?.filter((usr: any) =>
                            usr.grupos.find((g: any) => g.name === "sponsor")
                          )
                          ?.filter((usr: any) => usr.cliente_id === cliente_id)
                          ?.map((usr: any) => {
                            return {
                              key: usr._id,
                              label: usr.name,
                              onClick: (v: any) => {
                                setLoading(true);
                                moduloTask
                                  .updateTaskAssignment(task._id, usr._id)
                                  .finally(() => setLoading(false));
                              },
                            };
                          }),
                      },
                      {
                        key: "2",
                        type: "group",
                        label: "Outros",
                        children: usuarios
                          ?.filter((usr: any) =>
                            usr.grupos.find((g: any) => g.name !== "sponsor")
                          )
                          ?.filter((usr: any) => usr.cliente_id === cliente_id)
                          ?.map((usr: any) => {
                            return {
                              key: usr._id,
                              label: usr.name,
                              onClick: (v: any) => {
                                setLoading(true);
                                moduloTask
                                  .updateTaskAssignment(task._id, usr._id)
                                  .finally(() => setLoading(false));
                              },
                            };
                          }),
                      },
                    ],
                  }}
                  dropdownRender={(menu) => (
                    <div style={contentStyle}>
                      <Search
                        style={{
                          margin: "10px 18px",
                          width: "264px",
                        }}
                        placeholder="Buscar"
                        onSearch={handleSearch}
                        enterButton
                      />
                      <Divider style={{ margin: 0 }} />
                      {React.cloneElement(menu as React.ReactElement, {
                        style: menuStyle,
                      })}
                      <Divider style={{ margin: 0 }} />
                      <Space
                        style={{
                          padding: 8,
                          justifyContent: "flex-end",
                          display: "flex",
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<UserAddOutlined />}
                          onClick={() => setShowModalNewUser(true)}
                        >
                          Novo usuário
                        </Button>
                      </Space>
                    </div>
                  )}
                >
                  <div
                    className="microtask_user"
                    style={{ cursor: "pointer", marginLeft: "auto" }}
                  >
                    {task?.assignment_name && (
                      <Avatar
                        className="avatar-task"
                        src={`https://ui-avatars.com/api/?name=${task?.assignment_name?.replaceAll(
                          " ",
                          "+"
                        )}?background=random`}
                      />
                    )}

                    <p
                      onClick={() => {
                        if (task?.assignment_name) {
                        }
                      }}
                      style={
                        task?.assignment_name
                          ? {}
                          : { color: "#1677ff", cursor: "pointer" }
                      }
                    >
                      {truncateDescription(task?.assignment_name, 20) ||
                        "Definir atribuição"}
                    </p>
                    <EditOutlined style={{ marginLeft: "0.5rem" }} />
                  </div>
                </Dropdown>
              </Col>
            </Tooltip>
          </Row>
        );
      })}
      {!tarefasParaExibir?.[0] && (
        <Alert type="info" message="Nenhum item encontrado" showIcon />
      )}
      {reuniao.status !== "Realizada" && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "right" }}
        >
          <Button
            type="primary"
            className="mb-4"
            style={{ marginTop: "1rem" }}
            icon={<PlusOutlined />}
            onClick={handleShowModalCriar}
          >
            {/* {pautaId ? "Adicionar deliberação" : "Adicionar tarefa"} */}
            Adicionar tarefa
          </Button>
        </div>
      )}

      <CriarTarefa
        tipo={pautaId ? "tarefa-pauta" : "reuniao"}
        handleShowModal={handleShowModalCriar}
        showModal={criarTarefaModalVisible}
        modelo_referencia_id={pautaId ? undefined : reuniao._id}
        reuniaoId={reuniao._id}
        pautaId={pautaId}
      />
      <Task
        setShowModal={setIsVerTarefaModalVisible}
        showModal={isVerTarefaModalVisible}
      />
    </div>
  );
};

const Transcricao: React.FC<{
  reuniao: Reuniao;
}> = ({ reuniao }) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-4">6. Transcrição</h3>
      {reuniao.transcricaoAta ? (
        <MarkdownPreview
          source={reuniao.transcricaoAta?.replace(
            "Meeting notes are grouped according to your [grouping preferences.](https://tldv.io/app/settings/preferences)",
            ""
          )}
          style={{
            padding: "0rem 1.5rem 1.5rem 1.5rem",
            background: "transparent",
          }}
        />
      ) : (
        <Alert type="info" message="Transcrição pendente" showIcon />
      )}
    </div>
  );
};

const DiaDaReuniao: React.FC = () => {
  const location = useLocation();
  const { membroId, reuniao } = location.state || {};
  const [isSavingStatus, setIsSavingStatus] = useState<boolean>(false);
  const navigate = useNavigate();

  const { atualizarReuniaoPorId, currentCliente } = useContext(
    GlobalContext
  ) as GlobalContextType;

  useEffect(() => {
    if (!currentCliente?._id) {
      navigate("/cppd");
    }
  }, [currentCliente]);

  // Verifica se o membroId e a reunião foram passados corretamente
  if (!membroId || !reuniao) {
    return <div>Erro: Dados de membro ou reunião não encontrados</div>;
  }

  const handleSaveReuniaoStatus = async () => {
    setIsSavingStatus(true);

    await atualizarReuniaoPorId(reuniao.cppd_id, reuniao._id, {
      status: "Realizada",
    });

    message.success("Reunião finalizada com sucesso!");

    navigate(`/cppd/${reuniao.cppd_id}/reunioes`, {
      state: { activeReuniaoId: reuniao._id },
    });

    setIsSavingStatus(false);
  };

  return (
    <div className="container mx-auto p-6">
      {reuniao.status === "Realizada" && (
        <Alert
          message="Reunião realizada"
          type="success"
          showIcon
          style={{ marginBottom: "1rem" }}
        />
      )}
      <MembrosPresentes
        cliente_id={membroId}
        cppdId={reuniao.cppd_id}
        reuniao={reuniao}
      />
      {/* <OrdemDoDiaModal reuniaoId={reuniao._id} /> */}
      <FollowUpModal reuniao={reuniao} cliente_id={membroId} />
      <Deliberacoes reuniao={reuniao} cliente_id={membroId} />
      <TarefasReuniao cliente_id={membroId} reuniao={reuniao} />
      {/* <DefinirTarefas reuniaoId={reuniao._id} cliente_id={membroId} /> */}
      <Transcricao reuniao={reuniao} />
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={handleSaveReuniaoStatus}
        loading={isSavingStatus}
        disabled={reuniao.status === "Realizada" ? true : false}
      >
        Finalizar reunião
      </Button>
    </div>
  );
};

export default DiaDaReuniao;
