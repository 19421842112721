import React, { useState } from "react";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { Questionario } from "../../../../context/mapeamentoContext";
import "./styles.css";
import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  theme,
  Tooltip,
} from "antd";
import {
  CheckCircleFilled,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  LeftOutlined,
  LoadingOutlined,
  MailOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  RightCircleFilled,
  RightOutlined,
  UserAddOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import moment from "moment";
import { useParams } from "react-router-dom";
import { CapituloType } from "../../../../context/processosContext";
import SaveBtn from "../../../../components/SaveBtn/SaveBtn";
import GuiasMapeamento from "./components/GuiasMapeamento";
import Fichas from "../../../Modulos/Fichas/Fichas";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import NewUser from "../../../../components/NewUser/NewUser";
import QuestionarioPreliminar from "../Gestor/components/QuestionarioPreliminar";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";

const { useToken } = theme;

interface MapeamentoProps {
  currentMapeamento: Questionario | null | undefined; // ou qualquer outro tipo apropriado
  setCurrentMapeamento: (mapeamento: Questionario | any) => void;
  type: "sponsor" | "gestor" | "lider" | "consultor";
}

const { Option } = Select;

const MapeamentoCliente: React.FC<MapeamentoProps> = ({
  currentMapeamento,
  setCurrentMapeamento,
  type,
}) => {
  const {
    patchUser,
    usuarios,
    clientes,
    moduloMapeamento,
    moduloProcessos,
    postPrimeiroAcesso,
    currentCliente,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const params = useParams();

  const [newArea, setNewArea] = useState("");
  const [newProcess, setNewProcess] = useState<any>({});
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [selectedProcess, setSelectedProcess] = useState<CapituloType | null>(
    null
  );
  const [showFicha, setShowFicha] = useState<any>(false);
  const [fichaClienteId, setFichaClienteId] = useState<any>(null);
  const [fichaMapeamentoId, setFichaMapeamentoId] = useState<any>(null);
  const [fichaProcessoId, setFichaProcessoId] = useState<any>(null);
  const [fichaAreaId, setFichaAreaId] = useState<any>(null);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [showQuestionario, setShowQuestionario] = useState(false);
  const [clickedArea, setClickedArea] = useState<any | null>(null);

  const [loadingToggleProcess, setLoadingToggleProcess] = useState(false);

  const [formPreliminar] = Form.useForm();

  const toggleArea = (areaId: string) => {
    if (currentMapeamento && currentMapeamento.areas) {
      const currentAreas = currentMapeamento.areas;
      const newAreas = currentAreas.includes(areaId)
        ? currentAreas.filter((id) => id !== areaId)
        : [...currentAreas, areaId];
      setCurrentMapeamento({ ...currentMapeamento, areas: newAreas });

      moduloMapeamento?.saveQuestionario(currentMapeamento?.cliente_id, {
        ...currentMapeamento,
        areas: newAreas,
      });
    }
  };

  const toggleProcesso = (processo: CapituloType) => {
    if (currentMapeamento) {
      setLoadingToggleProcess(true);
      const currentProcessos = currentMapeamento.capitulos || [];

      const newProcesso = currentProcessos.find(
        (p: any) => p._id === processo._id
      )
        ? [
            ...currentProcessos.filter((p: any) => p._id !== processo._id),
            {
              ...currentProcessos.find((p: any) => p._id === processo._id),
              inativo: !currentProcessos.find(
                (p: any) => p._id === processo._id
              ).inativo,
            },
          ]
        : [...currentProcessos, { ...processo, areas_id: processo.tags }];

      setCurrentMapeamento({ ...currentMapeamento, capitulos: newProcesso });

      moduloMapeamento
        ?.saveQuestionario(currentMapeamento?.cliente_id, {
          ...currentMapeamento,
          capitulos: newProcesso,
        })
        .then((res) => setCurrentMapeamento(res?.[0]))
        .finally(() => setLoadingToggleProcess(false));
    }
  };

  const handleAddProcess = (area: string | undefined) => {
    if (area) {
      // posta o novo processo e retorna o _id
      moduloProcessos
        ?.postCapitulo({
          id: null,
          description: newProcess?.processo,
          norma: "",
          recomendacao: "",
          tags: [area],
          dependencia: null,
        })
        .then((processo: any) => toggleProcesso(processo));

      setNewProcess("");
    }
  };

  const handleAddSubProcess = (dependencia_id: string | undefined) => {
    if (dependencia_id) {
      // posta o novo processo e retorna o _id
      moduloProcessos?.postCapitulo({
        id: null,
        description: newProcess?.processo,
        norma: "",
        recomendacao: "",
        dependencia: dependencia_id,
      });

      setNewProcess("");
    }
  };

  const handleAddArea = () => {
    // cadastra nova area e retorna seu _id
    moduloProcessos?.postArea(newArea).then((area) => toggleArea(area._id));

    // Limpar campo de nova área
    setNewArea("");
  };

  const items: MenuProps["items"] =
    moduloProcessos?.areas
      ?.filter(
        (area) =>
          (!area.cliente_id && area.padrao) ||
          (area.cliente_id && area.cliente_id === currentMapeamento?.cliente_id)
      )
      ?.filter(
        (area) => !currentMapeamento?.areas?.find((a: string) => a === area._id)
      )
      ?.map((area: any) => ({
        key: area._id + "drop",
        label: area.description,
        onClick: () => toggleArea(area._id),
      })) || [];

  const itemsProcesso: MenuProps["items"] =
    moduloProcessos?.capitulos
      ?.filter((processo) =>
        processo.tags?.some((tag) =>
          currentMapeamento?.areas?.some((area) => area === tag)
        )
      )
      ?.filter(
        (processo) =>
          !currentMapeamento?.capitulos?.find(
            (p: any) => p._id === processo._id
          )
      )
      ?.map((processo: any) => ({
        key: processo._id + "drop",
        label: processo.description,
        onClick: () => toggleProcesso(processo),
      })) || [];

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };
  return selectedProcess ? (
    <GuiasMapeamento
      mapeamento={currentMapeamento}
      setMapeamento={setCurrentMapeamento}
      processo={selectedProcess}
      setProcesso={setSelectedProcess}
    />
  ) : (
    <div className="mapeamento-consultor">
      <BackHeader title="Mapeamentos" prev />

      {!showFicha && (
        <>
          {type === "consultor" ? (
            <>
              <h4>Dados da empresa</h4>
              <br />
              <Table
                size="small"
                dataSource={clientes?.filter(
                  (cli: any) =>
                    cli._id === currentCliente?._id ||
                    cli._id === params.clienteid
                )}
                pagination={false}
                columns={[
                  {
                    title: "Razão social",
                    dataIndex: "razao_social",
                    key: "razao_social",
                  },
                  {
                    title: "CNPJ",
                    dataIndex: "cnpj",
                    key: "cnpj",
                  },
                  {
                    title: "Responsável legal",
                    dataIndex: "representante",
                    key: "representante",
                  },
                ]}
              />
              <br />
            </>
          ) : (
            // Tutorial
            <Button
              style={{ marginLeft: "-1rem", marginBottom: "0.5rem" }}
              type="link"
              icon={<YoutubeOutlined />}
            >
              Assista o tutorial
            </Button>
          )}

          {/* Abordagem */}
          <>
            <CustomTooltip
              text={`Recomendamos a abordagem "Detalhada"`}
              visibleByDefault
              placement="right"
            >
              <h4 style={{ width: "fit-content" }}>1. Abordagem</h4>
            </CustomTooltip>
            <br />
            <Radio.Group
              onChange={(v) => {
                setCurrentMapeamento((prev: Questionario) => ({
                  ...prev,
                  abordagem: v.target.value,
                }));

                moduloMapeamento?.saveQuestionario(
                  currentMapeamento?.cliente_id,
                  {
                    ...currentMapeamento,
                    abordagem: v.target.value,
                    perguntasRelacao:
                      moduloProcessos?.capituloPerguntas?.filter((cp: any) =>
                        currentMapeamento?.capitulos
                          .map((c: any) => c._id)
                          .includes(cp.capitulo_id)
                      ),
                  }
                );
              }}
              defaultValue={currentMapeamento?.abordagem}
              value={currentMapeamento?.abordagem}
            >
              <Tooltip
                title="Ao selecionar esta opção, você receberá um fluxo de
                  subprocessos para cadastrar."
              >
                <Radio.Button value="detalhada">Detalhada</Radio.Button>
              </Tooltip>
              <Tooltip
                title="Caso você não tenha todos os subprocessos, você apenas
                  cadastrará as informações referentes ao processo escolhido."
              >
                <Radio.Button value="simplificada">Simplificada</Radio.Button>
              </Tooltip>
            </Radio.Group>
            <div />
          </>
          <br />
          {/* Áreas */}
          {currentMapeamento?.abordagem && (
            <>
              <h4>2. Áreas</h4>

              <br />
              <Collapse
                size="small"
                collapsible="icon"
                items={currentMapeamento?.areas
                  ?.filter((areaId: string) =>
                    moduloProcessos.areas?.find((a) => a._id === areaId)
                  )
                  ?.filter((areaId: any) => {
                    if (type === "lider") {
                      const areasEmProcessos = currentMapeamento.capitulos?.map(
                        (c: any) => c.tags?.[0]
                      );
                      return areasEmProcessos.includes(areaId);
                    } else {
                      return true;
                    }
                  })
                  ?.map((area: any, areaIndex: number) => {
                    const currentGestor = usuarios.find(
                      (user: any) =>
                        user.funcoesDetalhadas.find(
                          (funcao: any) =>
                            funcao.funcao_id.name === "view-area" &&
                            funcao.reference_model_id === area
                        ) &&
                        user.funcoesDetalhadas.find(
                          (funcao: any) =>
                            funcao.funcao_id.name ===
                              "responder-questionario" &&
                            funcao.reference_model_id === currentMapeamento._id
                        )
                    );

                    return {
                      key: area._id,
                      label: moduloProcessos?.areas?.find((a) => a._id === area)
                        ?.description,
                      children: (
                        <>
                          {moduloProcessos?.capitulos
                            ?.filter((processo) =>
                              processo.tags?.find((a) => a === area)
                            )
                            ?.filter((processo) =>
                              currentMapeamento?.capitulos?.find(
                                (p: any) => p._id === processo._id
                              )
                            )
                            ?.filter((processo: any) => {
                              if (type === "lider") {
                                return currentMapeamento.capitulos.find(
                                  (c: any) => c._id === processo._id
                                );
                              } else {
                                return true;
                              }
                            })
                            ?.filter(
                              (processo: any) => !processo.dependencia
                            )?.[0] && (
                            <>
                              {currentMapeamento?.abordagem === "detalhada" && (
                                <>
                                  {moduloProcessos?.capitulos
                                    ?.filter((processo) =>
                                      processo.tags?.find((a) => a === area)
                                    )
                                    ?.filter((processo) =>
                                      currentMapeamento?.capitulos?.find(
                                        (p: any) => p._id === processo._id
                                      )
                                    )
                                    ?.filter((processo: any) => {
                                      if (type === "lider") {
                                        return currentMapeamento.capitulos.find(
                                          (c: any) => c._id === processo._id
                                        );
                                      } else {
                                        return true;
                                      }
                                    })
                                    ?.filter(
                                      (processo: any) => !processo.dependencia
                                    )
                                    .map((processo: any) => {
                                      const currentLider = usuarios?.find(
                                        (user: any) =>
                                          user.funcoesDetalhadas.find(
                                            (funcao: any) =>
                                              funcao.funcao_id.name ===
                                                "responder-processo" &&
                                              funcao.reference_model_id ===
                                                processo._id
                                          ) &&
                                          user.funcoesDetalhadas.find(
                                            (funcao: any) =>
                                              funcao.funcao_id.name ===
                                                "responder-questionario" &&
                                              funcao.reference_model_id ===
                                                currentMapeamento._id
                                          )
                                      );
                                      return (
                                        <>
                                          <Tooltip
                                            title="Processo"
                                            placement="topLeft"
                                          >
                                            {type === "consultor" ||
                                            type === "sponsor" ||
                                            type === "gestor" ? (
                                              <h5
                                                style={{
                                                  fontSize: 18,
                                                  textAlign: "left",
                                                  marginBottom: "0.5rem",
                                                }}
                                              >
                                                {processo.description} -{" "}
                                                <>
                                                  <Select
                                                    placeholder="Delegar responsável pelo processo"
                                                    loading={loadingUser}
                                                    defaultValue={
                                                      currentLider?._id
                                                    }
                                                    disabled={
                                                      currentLider?._id
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(usrID) => {
                                                      if (usrID) {
                                                        setLoadingUser(true);
                                                        patchUser({
                                                          ...usuarios.find(
                                                            (usr: any) =>
                                                              usr._id === usrID
                                                          ),
                                                          grupos: [
                                                            ...usuarios.find(
                                                              (usr: any) =>
                                                                usr._id ===
                                                                usrID
                                                            )?.grupos,
                                                            "65f8a126c0e72d990258afa9",
                                                          ], // líder de processo
                                                          permissoes: [
                                                            ...usuarios
                                                              .find(
                                                                (usr: any) =>
                                                                  usr._id ===
                                                                  usrID
                                                              )
                                                              ?.funcoesDetalhadas?.map(
                                                                (
                                                                  permissao: any
                                                                ) => {
                                                                  return {
                                                                    key: permissao._id,
                                                                    name: permissao
                                                                      .funcao_id
                                                                      .name,
                                                                    description:
                                                                      permissao
                                                                        .funcao_id
                                                                        .description,
                                                                    reference_model_name:
                                                                      permissao
                                                                        .funcao_id
                                                                        .reference_model_name,
                                                                    reference_model_id:
                                                                      permissao.reference_model_id,
                                                                    funcao_id:
                                                                      permissao
                                                                        .funcao_id
                                                                        ._id,
                                                                  };
                                                                }
                                                              ),
                                                            ...[
                                                              // permissao responder-processo
                                                              {
                                                                reference_model_id:
                                                                  processo._id,
                                                                funcao_id:
                                                                  "65f8a0c1c0e72d990258afa4",
                                                              },
                                                              // responder-questionario
                                                              {
                                                                reference_model_id:
                                                                  currentMapeamento?._id,
                                                                funcao_id:
                                                                  "65fb2140c0e72d990258afc0",
                                                              },
                                                              // cadastrar-parceiro-fornecedor
                                                              {
                                                                reference_model_id:
                                                                  currentMapeamento?.cliente_id,
                                                                funcao_id:
                                                                  "65eefbc6ff2de399bce35a77",
                                                              },
                                                              // view-parceiro-fornecedor
                                                              {
                                                                reference_model_id:
                                                                  currentMapeamento?.cliente_id,
                                                                funcao_id:
                                                                  "65eefd46ff2de399bce35a80",
                                                              },
                                                            ],
                                                          ],
                                                        }).finally(() =>
                                                          setLoadingUser(false)
                                                        );
                                                      }
                                                    }}
                                                    size="small"
                                                    showSearch
                                                    optionFilterProp="label"
                                                    filterOption={(
                                                      input: string,
                                                      option: any
                                                    ) => {
                                                      return (
                                                        option.label
                                                          .toLowerCase()
                                                          .indexOf(
                                                            input.toLowerCase()
                                                          ) >= 0
                                                      );
                                                    }}
                                                    dropdownRender={(menu) => (
                                                      <div
                                                        style={{
                                                          ...contentStyle,
                                                        }}
                                                      >
                                                        {React.cloneElement(
                                                          menu as React.ReactElement,
                                                          {
                                                            style: menuStyle,
                                                          }
                                                        )}
                                                        <Divider
                                                          style={{
                                                            margin: 0,
                                                          }}
                                                        />
                                                        <Space
                                                          style={{
                                                            padding: 8,
                                                            justifyContent:
                                                              "flex-end",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <Button
                                                            type="primary"
                                                            icon={
                                                              <UserAddOutlined />
                                                            }
                                                            onClick={() =>
                                                              setShowModalNewUser(
                                                                true
                                                              )
                                                            }
                                                          >
                                                            Novo usuário
                                                          </Button>
                                                        </Space>
                                                      </div>
                                                    )}
                                                    style={{
                                                      width: 350,
                                                      marginRight: "0.5rem",
                                                      marginLeft: "0.5rem",
                                                    }}
                                                  >
                                                    {usuarios
                                                      ?.filter(
                                                        (u: any) =>
                                                          u.cliente_id ===
                                                          currentCliente?._id
                                                      )
                                                      ?.filter(
                                                        (u: any) =>
                                                          !u.grupos
                                                            ?.map(
                                                              (g: any) => g.name
                                                            )
                                                            .includes(
                                                              "Gestor de área"
                                                            ) &&
                                                          !u.grupos
                                                            ?.map(
                                                              (g: any) => g.name
                                                            )
                                                            .includes("sponsor")
                                                      )
                                                      .map((user: any) => (
                                                        <Option
                                                          key={user._id}
                                                          value={user._id}
                                                          label={user.name}
                                                        >
                                                          {user.name}
                                                        </Option>
                                                      ))}
                                                  </Select>
                                                  {currentLider && (
                                                    <Tooltip
                                                      title={
                                                        currentLider.envios?.find(
                                                          (envio: any) =>
                                                            envio.subject ===
                                                            "Seusdados - Bem-vindo à Plataforma Workplace"
                                                        )
                                                          ? "Enviar novamente - Último envio: " +
                                                            moment(
                                                              new Date(
                                                                currentLider.envios?.find(
                                                                  (
                                                                    envio: any
                                                                  ) =>
                                                                    envio.subject ===
                                                                    "Seusdados - Bem-vindo à Plataforma Workplace"
                                                                ).created_at
                                                              )
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "Enviar primeiro acesso"
                                                      }
                                                    >
                                                      <Button
                                                        icon={
                                                          currentLider.envios?.find(
                                                            (envio: any) =>
                                                              envio.subject ===
                                                              "Seusdados - Bem-vindo à Plataforma Workplace"
                                                          ) ? (
                                                            <CheckOutlined />
                                                          ) : (
                                                            <MailOutlined />
                                                          )
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            currentLider.envios?.find(
                                                              (envio: any) =>
                                                                envio.subject ===
                                                                "Seusdados - Bem-vindo à Plataforma Workplace"
                                                            )
                                                              ? "#008C76"
                                                              : "",
                                                        }}
                                                        size="small"
                                                        type="primary"
                                                        onClick={() => {
                                                          postPrimeiroAcesso(
                                                            currentLider
                                                          );
                                                        }}
                                                      >
                                                        {currentLider.envios?.find(
                                                          (envio: any) =>
                                                            envio.subject ===
                                                            "Seusdados - Bem-vindo à Plataforma Workplace"
                                                        )
                                                          ? "Enviado"
                                                          : "Enviar"}
                                                      </Button>
                                                    </Tooltip>
                                                  )}
                                                </>
                                              </h5>
                                            ) : (
                                              <h5
                                                style={{
                                                  fontSize: 18,
                                                  textAlign: "left",
                                                  marginBottom: "0.5rem",
                                                }}
                                              >
                                                {processo.description}
                                              </h5>
                                            )}
                                          </Tooltip>
                                          <Table
                                            size="small"
                                            pagination={{ pageSize: 5 }}
                                            bordered
                                            tableLayout="fixed"
                                            dataSource={moduloProcessos?.capitulos
                                              ?.filter((process: any) =>
                                                currentMapeamento?.capitulos?.find(
                                                  (p: any) =>
                                                    p._id === process._id
                                                )
                                              )
                                              ?.filter(
                                                (process: any) =>
                                                  !process.inativo
                                              )
                                              ?.filter(
                                                (process: any) =>
                                                  process.dependencia ===
                                                  processo._id
                                              )}
                                            columns={[
                                              {
                                                title: "Processo",
                                                dataIndex: "description",
                                                key: "description",
                                              },
                                              {
                                                title: "Data de início",
                                                dataIndex: "created_at",
                                                key: "created_at",
                                                width: 200,
                                                render: (
                                                  value: any,
                                                  record
                                                ) => {
                                                  const currentMapeamentoProcessoRelacaoId =
                                                    currentMapeamento.capitulos?.find(
                                                      (c: any) =>
                                                        c._id === record._id
                                                    )?.questionarioCapitulo
                                                      ?._id;

                                                  return moduloMapeamento?.currentTitulares?.find(
                                                    (titular) =>
                                                      titular.relacao_id ===
                                                      currentMapeamentoProcessoRelacaoId
                                                  )?.created_at ? (
                                                    <p style={{ fontSize: 12 }}>
                                                      {moment(
                                                        new Date(
                                                          moduloMapeamento?.currentTitulares?.find(
                                                            (titular) =>
                                                              titular.relacao_id ===
                                                              currentMapeamentoProcessoRelacaoId
                                                          )?.created_at || ""
                                                        )
                                                      ).format(
                                                        "DD/MM/YYYY HH:mm"
                                                      )}
                                                    </p>
                                                  ) : (
                                                    "-"
                                                  );
                                                },
                                              },
                                              {
                                                title: "Status",
                                                dataIndex: "description",
                                                key: "description",
                                                width: 200,

                                                render: (_, record) => {
                                                  let status = "Pendente";

                                                  const currentMapeamentoProcessoRelacaoId =
                                                    currentMapeamento.capitulos?.find(
                                                      (c: any) =>
                                                        c._id === record._id
                                                    )?.questionarioCapitulo
                                                      ?._id;

                                                  if (
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.dados?.[0] &&
                                                        titular.faixa_etaria &&
                                                        titular.categoria &&
                                                        titular.quantidade &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    ) ||
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.dados?.[0]
                                                          ?.frequencia &&
                                                        (titular.dados?.[0]
                                                          .bases_legais_comuns ||
                                                          titular.dados?.[0]
                                                            .bases_legais_sensiveis) &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    ) ||
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.dados?.[0] &&
                                                        (titular.dados?.[0]
                                                          .areas_compartilhadas?.[0] ||
                                                          titular.dados?.[0]
                                                            .externos?.[0]) &&
                                                        titular.dados?.[0]
                                                          .compartilhamento?.[0] &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    ) ||
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.categoria &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    )
                                                  ) {
                                                    status = "Em andamento";
                                                  }

                                                  if (
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.dados?.[0] &&
                                                        titular.faixa_etaria &&
                                                        titular.categoria &&
                                                        titular.quantidade &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    ) &&
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.dados?.[0]
                                                          ?.frequencia &&
                                                        (titular.dados?.[0]
                                                          .bases_legais_comuns ||
                                                          titular.dados?.[0]
                                                            .bases_legais_sensiveis) &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    ) &&
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.dados?.[0] &&
                                                        (titular.dados?.[0]
                                                          .areas_compartilhadas?.[0] ||
                                                          titular.dados?.[0]
                                                            .externos?.[0]) &&
                                                        titular.dados?.[0]
                                                          .compartilhamento?.[0] &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    ) &&
                                                    moduloMapeamento?.currentTitulares?.find(
                                                      (titular) =>
                                                        titular.dados?.[0] &&
                                                        titular.dados?.[0]
                                                          .local?.[0] &&
                                                        titular.dados?.[0]
                                                          .prazo &&
                                                        titular.relacao_id ===
                                                          currentMapeamentoProcessoRelacaoId
                                                    )
                                                  ) {
                                                    status = "Concluído";
                                                  }

                                                  return (
                                                    <p
                                                      style={{
                                                        color:
                                                          status ===
                                                          "Em andamento"
                                                            ? "#0368c8"
                                                            : status ===
                                                              "Concluído"
                                                            ? "#008C76"
                                                            : "",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {status}
                                                    </p>
                                                  );
                                                },
                                              },
                                              {
                                                title: "Ações",
                                                dataIndex: "_id",
                                                key: "_id",
                                                width: 350,
                                                render: (_, record: any) => (
                                                  <>
                                                    <Button
                                                      size="small"
                                                      loading={
                                                        loadingToggleProcess
                                                      }
                                                      type="primary"
                                                      onClick={() => {
                                                        setSelectedProcess(
                                                          currentMapeamento.capitulos?.find(
                                                            (c: any) =>
                                                              c._id ===
                                                              record._id
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      Ir para mapeamento{" "}
                                                      <RightCircleFilled
                                                        style={{
                                                          color: "#fff",
                                                        }}
                                                      />
                                                    </Button>
                                                    <Button
                                                      // icon={<RightOutlined />}
                                                      style={{
                                                        backgroundColor:
                                                          "#008C76",
                                                        marginLeft: "1rem",
                                                      }}
                                                      size="small"
                                                      type="primary"
                                                      onClick={() => {
                                                        setFichaAreaId(
                                                          currentMapeamento
                                                            ?.areas?.[0]
                                                        );
                                                        setFichaProcessoId(
                                                          record._id
                                                        );
                                                        setFichaMapeamentoId(
                                                          currentMapeamento._id
                                                        );
                                                        setFichaClienteId(
                                                          currentMapeamento.cliente_id
                                                        );

                                                        setShowFicha(true);
                                                      }}
                                                    >
                                                      Ficha de processo{" "}
                                                      <RightCircleFilled
                                                        style={{
                                                          color: "#fff",
                                                        }}
                                                      />
                                                    </Button>
                                                  </>
                                                ),
                                              },
                                            ]}
                                          />
                                          <br />
                                          <Dropdown
                                            menu={{
                                              items:
                                                moduloProcessos?.capitulos
                                                  ?.filter(
                                                    (process: any) =>
                                                      !process.inativo &&
                                                      process.dependencia ===
                                                        processo._id
                                                  )
                                                  ?.filter(
                                                    (process) =>
                                                      !currentMapeamento?.capitulos?.find(
                                                        (p: any) =>
                                                          p._id === process._id
                                                      )
                                                  )
                                                  ?.map((processo: any) => ({
                                                    key: processo._id + "drop",
                                                    label: processo.description,
                                                    onClick: () =>
                                                      toggleProcesso(processo),
                                                  })) || [],
                                            }}
                                            dropdownRender={(menu) => (
                                              <div style={contentStyle}>
                                                {React.cloneElement(
                                                  menu as React.ReactElement,
                                                  {
                                                    style: menuStyle,
                                                  }
                                                )}
                                                <Divider
                                                  style={{ margin: 0 }}
                                                />
                                                <Space style={{ padding: 8 }}>
                                                  <Space>
                                                    <Input
                                                      value={
                                                        newProcess.dependencia_id ===
                                                        processo._id
                                                          ? newProcess.processo
                                                          : ""
                                                      }
                                                      onChange={(e) =>
                                                        setNewProcess({
                                                          processo:
                                                            e.target.value,
                                                          dependencia_id:
                                                            processo._id,
                                                        })
                                                      }
                                                      placeholder="Novo processo"
                                                      style={{ width: 200 }}
                                                    />
                                                    <Button
                                                      type="primary"
                                                      onClick={() =>
                                                        handleAddSubProcess(
                                                          processo._id
                                                        )
                                                      }
                                                      disabled={
                                                        newProcess.dependencia_id !==
                                                        processo._id
                                                      }
                                                      icon={
                                                        <PlusCircleOutlined />
                                                      }
                                                    >
                                                      Adicionar
                                                    </Button>
                                                  </Space>
                                                </Space>
                                              </div>
                                            )}
                                          >
                                            <Button
                                              type="dashed"
                                              size="small"
                                              icon={<PlusOutlined />}
                                              style={{
                                                width: "100%",
                                                color: "#5f29cc",
                                                borderColor: "#5f29cc",
                                              }}
                                            >
                                              Adicionar processo em
                                              <b> {processo.description}</b>
                                            </Button>
                                          </Dropdown>
                                          <div />
                                          <br />
                                        </>
                                      );
                                    })}
                                </>
                              )}

                              {currentMapeamento?.abordagem ===
                                "simplificada" && (
                                <>
                                  <Table
                                    size="small"
                                    bordered
                                    pagination={
                                      currentMapeamento?.capitulos
                                        ?.filter((processo: any) =>
                                          processo.tags?.find(
                                            (a: any) => a === area
                                          )
                                        )
                                        ?.filter(
                                          (process: any) => !process.inativo
                                        )
                                        ?.filter(
                                          (process: any) => !process.dependencia
                                        )?.length > 5
                                        ? { pageSize: 5 }
                                        : false
                                    }
                                    dataSource={moduloProcessos?.capitulos
                                      ?.filter((processo) =>
                                        processo.tags?.find((a) => a === area)
                                      )
                                      ?.filter((processo) =>
                                        currentMapeamento?.capitulos?.find(
                                          (p: any) => p._id === processo._id
                                        )
                                      )
                                      ?.filter((processo: any) => {
                                        if (type === "lider") {
                                          return currentMapeamento.capitulos.find(
                                            (c: any) => c._id === processo._id
                                          );
                                        } else {
                                          return true;
                                        }
                                      })
                                      ?.filter(
                                        (processo: any) => !processo.dependencia
                                      )}
                                    columns={[
                                      {
                                        title: "Processo",
                                        dataIndex: "description",
                                        key: "description",
                                      },
                                      {
                                        title: "Data de início",
                                        dataIndex: "created_at",
                                        key: "created_at",
                                        width: 200,
                                        render: (value: any, record) => {
                                          const currentMapeamentoProcessoRelacaoId =
                                            currentMapeamento.capitulos?.find(
                                              (c: any) => c._id === record._id
                                            )?.questionarioCapitulo?._id;

                                          return moduloMapeamento?.currentTitulares?.find(
                                            (titular) =>
                                              titular.relacao_id ===
                                              currentMapeamentoProcessoRelacaoId
                                          )?.created_at ? (
                                            <p style={{ fontSize: 12 }}>
                                              {moment(
                                                new Date(
                                                  moduloMapeamento?.currentTitulares?.find(
                                                    (titular) =>
                                                      titular.relacao_id ===
                                                      currentMapeamentoProcessoRelacaoId
                                                  )?.created_at || ""
                                                )
                                              ).format("DD/MM/YYYY HH:mm")}
                                            </p>
                                          ) : (
                                            "-"
                                          );
                                        },
                                      },
                                      {
                                        title: "Status",
                                        dataIndex: "description",
                                        key: "description",
                                        render: () => (
                                          <p
                                            style={{
                                              color: "#0368c8",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Em andamento
                                          </p>
                                        ),
                                      },
                                      ...(type === "sponsor" ||
                                      type === "gestor" ||
                                      type === "consultor"
                                        ? [
                                            {
                                              title: "Reponsável do processo",
                                              dataIndex: "_id",
                                              key: "_id",
                                              width: 400,
                                              render: (
                                                processoID: string,
                                                record: any
                                              ) => (
                                                <>
                                                  <Select
                                                    placeholder="Delegar"
                                                    loading={loadingUser}
                                                    defaultValue={
                                                      usuarios?.find(
                                                        (user: any) =>
                                                          user.funcoesDetalhadas.find(
                                                            (funcao: any) =>
                                                              funcao.funcao_id
                                                                .name ===
                                                                "responder-processo" &&
                                                              funcao.reference_model_id ===
                                                                processoID
                                                          ) &&
                                                          user.funcoesDetalhadas.find(
                                                            (funcao: any) =>
                                                              funcao.funcao_id
                                                                .name ===
                                                                "responder-questionario" &&
                                                              funcao.reference_model_id ===
                                                                currentMapeamento._id
                                                          )
                                                      )?._id
                                                    }
                                                    disabled={
                                                      usuarios?.find(
                                                        (user: any) =>
                                                          user.funcoesDetalhadas.find(
                                                            (funcao: any) =>
                                                              funcao.funcao_id
                                                                .name ===
                                                                "responder-processo" &&
                                                              funcao.reference_model_id ===
                                                                processoID
                                                          ) &&
                                                          user.funcoesDetalhadas.find(
                                                            (funcao: any) =>
                                                              funcao.funcao_id
                                                                .name ===
                                                                "responder-questionario" &&
                                                              funcao.reference_model_id ===
                                                                currentMapeamento._id
                                                          )
                                                      )?._id
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(usrID) => {
                                                      if (usrID) {
                                                        setLoadingUser(true);
                                                        patchUser({
                                                          ...usuarios.find(
                                                            (usr: any) =>
                                                              usr._id === usrID
                                                          ),
                                                          grupos: [
                                                            ...usuarios.find(
                                                              (usr: any) =>
                                                                usr._id ===
                                                                usrID
                                                            )?.grupos,
                                                            "65f8a126c0e72d990258afa9",
                                                          ], // líder de processo
                                                          permissoes: [
                                                            ...usuarios
                                                              .find(
                                                                (usr: any) =>
                                                                  usr._id ===
                                                                  usrID
                                                              )
                                                              ?.funcoesDetalhadas?.map(
                                                                (
                                                                  permissao: any
                                                                ) => {
                                                                  return {
                                                                    key: permissao._id,
                                                                    name: permissao
                                                                      .funcao_id
                                                                      .name,
                                                                    description:
                                                                      permissao
                                                                        .funcao_id
                                                                        .description,
                                                                    reference_model_name:
                                                                      permissao
                                                                        .funcao_id
                                                                        .reference_model_name,
                                                                    reference_model_id:
                                                                      permissao.reference_model_id,
                                                                    funcao_id:
                                                                      permissao
                                                                        .funcao_id
                                                                        ._id,
                                                                  };
                                                                }
                                                              ),
                                                            ...[
                                                              // permissao responder-processo
                                                              {
                                                                reference_model_id:
                                                                  processoID,
                                                                funcao_id:
                                                                  "65f8a0c1c0e72d990258afa4",
                                                              },
                                                              // responder-questionario
                                                              {
                                                                reference_model_id:
                                                                  currentMapeamento?._id,
                                                                funcao_id:
                                                                  "65fb2140c0e72d990258afc0",
                                                              },
                                                              // cadastrar-parceiro-fornecedor
                                                              {
                                                                reference_model_id:
                                                                  currentMapeamento?.cliente_id,
                                                                funcao_id:
                                                                  "65eefbc6ff2de399bce35a77",
                                                              },
                                                              // view-parceiro-fornecedor
                                                              {
                                                                reference_model_id:
                                                                  currentMapeamento?.cliente_id,
                                                                funcao_id:
                                                                  "65eefd46ff2de399bce35a80",
                                                              },
                                                            ],
                                                          ],
                                                        }).finally(() =>
                                                          setLoadingUser(false)
                                                        );
                                                      }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="label"
                                                    filterOption={(
                                                      input: string,
                                                      option: any
                                                    ) => {
                                                      return (
                                                        option.label
                                                          .toLowerCase()
                                                          .indexOf(
                                                            input.toLowerCase()
                                                          ) >= 0
                                                      );
                                                    }}
                                                    dropdownRender={(menu) => (
                                                      <div
                                                        style={{
                                                          ...contentStyle,
                                                        }}
                                                      >
                                                        {React.cloneElement(
                                                          menu as React.ReactElement,
                                                          {
                                                            style: menuStyle,
                                                          }
                                                        )}
                                                        <Divider
                                                          style={{
                                                            margin: 0,
                                                          }}
                                                        />
                                                        <Space
                                                          style={{
                                                            padding: 8,
                                                            justifyContent:
                                                              "flex-end",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <Button
                                                            type="primary"
                                                            icon={
                                                              <UserAddOutlined />
                                                            }
                                                            onClick={() =>
                                                              setShowModalNewUser(
                                                                true
                                                              )
                                                            }
                                                          >
                                                            Novo usuário
                                                          </Button>
                                                        </Space>
                                                      </div>
                                                    )}
                                                    style={{
                                                      width: 350,
                                                      marginRight: "2rem",
                                                      marginLeft: "0.5rem",
                                                    }}
                                                  >
                                                    {usuarios
                                                      ?.filter(
                                                        (u: any) =>
                                                          u.cliente_id ===
                                                          currentCliente?._id
                                                      )
                                                      ?.filter(
                                                        (u: any) =>
                                                          !u.grupos
                                                            ?.map(
                                                              (g: any) => g.name
                                                            )
                                                            .includes(
                                                              "Gestor de área"
                                                            ) &&
                                                          !u.grupos
                                                            ?.map(
                                                              (g: any) => g.name
                                                            )
                                                            .includes("sponsor")
                                                      )
                                                      .map((user: any) => (
                                                        <Option
                                                          key={user._id}
                                                          value={user._id}
                                                          label={user.name}
                                                        >
                                                          {user.name}
                                                        </Option>
                                                      ))}
                                                  </Select>
                                                </>
                                              ),
                                            },
                                          ]
                                        : []),
                                      {
                                        title: "Ações",
                                        dataIndex: "_id",
                                        key: "_id",
                                        render: (_, record: any) => (
                                          <>
                                            <Button
                                              // icon={<RightOutlined />}
                                              style={
                                                {
                                                  // backgroundColor: "#008C76",
                                                }
                                              }
                                              loading={loadingToggleProcess}
                                              size="small"
                                              type="primary"
                                              onClick={() => {
                                                setSelectedProcess(
                                                  currentMapeamento.capitulos?.find(
                                                    (c: any) =>
                                                      c._id === record._id
                                                  )
                                                );
                                              }}
                                            >
                                              Ir para mapeamento{" "}
                                              <RightCircleFilled
                                                style={{ color: "#fff" }}
                                              />
                                            </Button>
                                            <Button
                                              // icon={<RightOutlined />}
                                              style={{
                                                backgroundColor: "#008C76",
                                                marginLeft: "1rem",
                                              }}
                                              size="small"
                                              type="primary"
                                              onClick={() => {
                                                setFichaAreaId(
                                                  currentMapeamento?.areas?.[0]
                                                );
                                                setFichaProcessoId(record._id);
                                                setFichaMapeamentoId(
                                                  currentMapeamento._id
                                                );
                                                setFichaClienteId(
                                                  currentMapeamento.cliente_id
                                                );
                                                setFichaClienteId(
                                                  currentMapeamento.cliente_id
                                                );
                                                setShowFicha(true);
                                              }}
                                            >
                                              Ficha de processo{" "}
                                              <RightCircleFilled
                                                style={{ color: "#fff" }}
                                              />
                                            </Button>
                                          </>
                                        ),
                                      },
                                    ]}
                                  />
                                  <br />
                                </>
                              )}
                            </>
                          )}
                          {(type === "sponsor" ||
                            type === "gestor" ||
                            type === "consultor") && (
                            <Dropdown
                              menu={{ items: itemsProcesso }}
                              dropdownRender={(menu) => (
                                <div style={contentStyle}>
                                  {React.cloneElement(
                                    menu as React.ReactElement,
                                    {
                                      style: menuStyle,
                                    }
                                  )}
                                  <Divider style={{ margin: 0 }} />
                                  <Space style={{ padding: 8 }}>
                                    <Space>
                                      <Input
                                        value={
                                          newProcess.area_id === area
                                            ? newProcess.processo
                                            : ""
                                        }
                                        onChange={(e) =>
                                          setNewProcess({
                                            processo: e.target.value,
                                            area_id: area,
                                          })
                                        }
                                        placeholder="Novo processo"
                                        style={{ width: 200 }}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={() => handleAddProcess(area)}
                                        disabled={newProcess.area_id !== area}
                                        icon={<PlusCircleOutlined />}
                                      >
                                        Adicionar
                                      </Button>
                                    </Space>
                                  </Space>
                                </div>
                              )}
                            >
                              <Button
                                type="dashed"
                                size="large"
                                icon={<PlusOutlined />}
                                style={{
                                  width: "100%",
                                  color: "#5f29cc",
                                  borderColor: "#5f29cc",
                                }}
                              >
                                Adicionar atividade
                              </Button>
                            </Dropdown>
                          )}
                        </>
                      ),
                      extra: (
                        <div className="d-flex a-c">
                          {/* Delegar responsável de área */}
                          {(type === "sponsor" || type === "consultor") && (
                            <div
                              style={{
                                marginRight: "2rem",
                              }}
                            >
                              {areaIndex === 0 ? (
                                <CustomTooltip
                                  text="Escolha um responsável para delegar preenchimento"
                                  visibleByDefault
                                >
                                  <span>Responsável da área:</span>
                                </CustomTooltip>
                              ) : (
                                <span>Responsável da área:</span>
                              )}

                              <Select
                                placeholder="Selecione"
                                size="small"
                                loading={loadingUser}
                                defaultValue={currentGestor?._id}
                                disabled={currentGestor ? true : false}
                                onChange={(usrID) => {
                                  if (usrID) {
                                    setLoadingUser(true);
                                    patchUser({
                                      ...usuarios.find(
                                        (usr: any) => usr._id === usrID
                                      ),
                                      grupos: [
                                        ...usuarios.find(
                                          (usr: any) => usr._id === usrID
                                        )?.grupos,
                                        "65f98308c0e72d990258afaf",
                                      ], // grupo gestor area
                                      permissoes: [
                                        ...usuarios
                                          .find((usr: any) => usr._id === usrID)
                                          ?.funcoesDetalhadas?.map(
                                            (permissao: any) => {
                                              return {
                                                key: permissao._id,
                                                name: permissao.funcao_id.name,
                                                description:
                                                  permissao.funcao_id
                                                    .description,
                                                reference_model_name:
                                                  permissao.funcao_id
                                                    .reference_model_name,
                                                reference_model_id:
                                                  permissao.reference_model_id,
                                                funcao_id:
                                                  permissao.funcao_id._id,
                                              };
                                            }
                                          ),
                                        {
                                          reference_model_id: area,
                                          funcao_id: "65f982dcc0e72d990258afad",
                                        },
                                        {
                                          reference_model_id:
                                            currentMapeamento?._id,
                                          funcao_id: "65fb2140c0e72d990258afc0",
                                        },
                                      ],
                                    }).finally(() => setLoadingUser(false));
                                  }
                                }}
                                showSearch
                                optionFilterProp="label"
                                filterOption={(input: string, option: any) => {
                                  return (
                                    option.label
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                dropdownRender={(menu) => (
                                  <div
                                    style={{
                                      ...contentStyle,
                                    }}
                                  >
                                    {React.cloneElement(
                                      menu as React.ReactElement,
                                      {
                                        style: menuStyle,
                                      }
                                    )}
                                    <Divider style={{ margin: 0 }} />
                                    <Space
                                      style={{
                                        padding: 8,
                                        justifyContent: "flex-end",
                                        display: "flex",
                                      }}
                                    >
                                      <Button
                                        type="primary"
                                        icon={<UserAddOutlined />}
                                        onClick={() =>
                                          setShowModalNewUser(true)
                                        }
                                      >
                                        Novo usuário
                                      </Button>
                                    </Space>
                                  </div>
                                )}
                                style={{
                                  width: 350,
                                  marginRight: "0.5rem",
                                  marginLeft: "0.5rem",
                                }}
                              >
                                {usuarios
                                  ?.filter(
                                    (u: any) =>
                                      u.cliente_id === currentCliente?._id
                                  )
                                  .map((user: any) => (
                                    <Option
                                      key={user._id}
                                      value={user._id}
                                      label={user.name}
                                    >
                                      {user.name}
                                    </Option>
                                  ))}
                              </Select>
                              {currentGestor && (
                                <Tooltip
                                  title={
                                    currentGestor.envios?.find(
                                      (envio: any) =>
                                        envio.subject ===
                                        "Seusdados - Bem-vindo à Plataforma Workplace"
                                    )
                                      ? "Enviar novamente - Último envio: " +
                                        moment(
                                          new Date(
                                            currentGestor.envios?.find(
                                              (envio: any) =>
                                                envio.subject ===
                                                "Seusdados - Bem-vindo à Plataforma Workplace"
                                            ).created_at
                                          )
                                        ).format("DD/MM/YYYY HH:mm")
                                      : "Enviar primeiro acesso"
                                  }
                                >
                                  <Button
                                    icon={
                                      currentGestor.envios?.find(
                                        (envio: any) =>
                                          envio.subject ===
                                          "Seusdados - Bem-vindo à Plataforma Workplace"
                                      ) ? (
                                        <CheckOutlined />
                                      ) : (
                                        <MailOutlined />
                                      )
                                    }
                                    style={{
                                      backgroundColor:
                                        currentGestor.envios?.find(
                                          (envio: any) =>
                                            envio.subject ===
                                            "Seusdados - Bem-vindo à Plataforma Workplace"
                                        )
                                          ? "#008C76"
                                          : "",
                                    }}
                                    size="small"
                                    type="primary"
                                    onClick={() => {
                                      postPrimeiroAcesso(currentGestor);
                                    }}
                                  >
                                    {currentGestor.envios?.find(
                                      (envio: any) =>
                                        envio.subject ===
                                        "Seusdados - Bem-vindo à Plataforma Workplace"
                                    )
                                      ? "Enviado"
                                      : "Enviar"}
                                  </Button>
                                </Tooltip>
                              )}
                            </div>
                          )}

                          {(type === "sponsor" || type === "consultor") && (
                            <Tooltip title="Remover área?">
                              <Button
                                type="link"
                                style={{ color: "red" }}
                                key={area._id + "btn"}
                                size="small"
                                icon={<DeleteOutlined />}
                                onClick={() => toggleArea(area)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      ),
                    };
                  })}
              />
              <br />
              {/* Campo para adicionar nova área */}
              {(type === "sponsor" || type === "consultor") && (
                <Dropdown
                  menu={{ items }}
                  dropdownRender={(menu) => (
                    <div style={contentStyle}>
                      {React.cloneElement(menu as React.ReactElement, {
                        style: menuStyle,
                      })}
                      <Divider style={{ margin: 0 }} />
                      <Space style={{ padding: 8 }}>
                        <Space>
                          <Input
                            value={newArea}
                            onChange={(e) => setNewArea(e.target.value)}
                            placeholder="Digite o nome da nova área"
                            style={{ width: 300 }}
                          />
                          <Button
                            type="primary"
                            onClick={handleAddArea}
                            disabled={!newArea.trim()}
                            icon={<PlusCircleOutlined />}
                          >
                            Cadastrar
                          </Button>
                        </Space>
                      </Space>
                    </div>
                  )}
                >
                  <Button
                    type="dashed"
                    size="large"
                    icon={<PlusOutlined />}
                    style={{
                      width: "100%",
                      color: "#5f29cc",
                      borderColor: "#5f29cc",
                    }}
                  >
                    Adicionar área
                  </Button>
                </Dropdown>
              )}
              <div></div>
            </>
          )}
          <br />
          {/* Questionário preliminar */}
          {currentMapeamento?.abordagem && (
            <>
              {(type === "consultor" ||
                type === "sponsor" ||
                type === "gestor") && (
                <>
                  <h4>3. Questionário preliminar</h4>
                  <br />
                  <>
                    {currentMapeamento?.areas?.find((areaId: string) =>
                      moduloProcessos.areas?.find((a) => a._id === areaId)
                    ) ? (
                      <Button
                        onClick={() => setShowQuestionario(true)}
                        type="primary"
                        style={{ background: "#008C76" }}
                        icon={<FormOutlined />}
                      >
                        Ver questionário preliminar
                      </Button>
                    ) : (
                      <Alert
                        type="warning"
                        message="Adicione ao menos uma área para prosseguir."
                        showIcon
                      />
                    )}
                  </>
                </>
              )}
            </>
          )}
          <SaveBtn
            text="Salvar"
            loading={loadingSave}
            onClick={() => {
              setLoadingSave(true);
              moduloMapeamento
                ?.saveQuestionario(
                  currentMapeamento?.cliente_id,
                  currentMapeamento
                )
                .finally(() => setLoadingSave(false));
            }}
          />
        </>
      )}

      {showFicha &&
        fichaClienteId &&
        fichaMapeamentoId &&
        fichaAreaId &&
        fichaProcessoId && (
          <Fichas
            backHeaderFunction={() => setShowFicha(false)}
            filtrosFeitos={{
              cliente: fichaClienteId,
              mapeamento: fichaMapeamentoId,
              area: fichaAreaId,
              processo: fichaProcessoId,
            }}
          />
        )}

      <NewUser
        showModal={showModalNewUser}
        setShowModal={setShowModalNewUser}
      />

      <Modal
        open={showQuestionario}
        onCancel={() => setShowQuestionario(false)}
        width={900}
        footer={false}
      >
        <div className="mapeamentos_containner">
          <div
            className="mapeamentos_steps"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div>
              <h5>1. Selecione uma área:</h5>
              <br />
              {currentMapeamento?.areas
                ?.filter(
                  (area: any) =>
                    moduloProcessos.areas?.find((a) => a._id === area)
                      ?.description
                )
                ?.map((area: any, areaIndex: number) => {
                  return (
                    <div>
                      {areaIndex === 0 ? (
                        <CustomTooltip
                          text={`Clique para selecionar"`}
                          visibleByDefault
                          placement="right"
                        >
                          <Button
                            key={area}
                            type={clickedArea === area ? "primary" : "default"}
                            style={{
                              marginBottom: "0.25rem",
                              // background: "#3333FF",
                            }}
                            size="small"
                            icon={
                              <RightCircleFilled
                                style={{
                                  color:
                                    clickedArea === area ? "#fff" : "#3333FF",
                                }}
                              />
                            }
                            onClick={() => {
                              formPreliminar.resetFields();
                              setClickedArea(area);
                            }}
                          >
                            {
                              moduloProcessos.areas?.find((a) => a._id === area)
                                ?.description
                            }
                          </Button>
                        </CustomTooltip>
                      ) : (
                        <Button
                          key={area}
                          type={clickedArea === area ? "primary" : "default"}
                          style={{
                            marginBottom: "0.25rem",
                            // background: "#3333FF",
                          }}
                          size="small"
                          icon={
                            <RightCircleFilled
                              style={{
                                color:
                                  clickedArea === area ? "#fff" : "#3333FF",
                              }}
                            />
                          }
                          onClick={() => {
                            formPreliminar.resetFields();
                            setClickedArea(area);
                          }}
                        >
                          {
                            moduloProcessos.areas?.find((a) => a._id === area)
                              ?.description
                          }
                        </Button>
                      )}
                    </div>
                  );
                })}
              <br />
            </div>
          </div>
          <div
            className={
              clickedArea
                ? "mapeamentos_steps triangulo"
                : "mapeamentos_steps--hidden"
            }
            style={{ width: "100%" }}
          >
            {clickedArea && currentMapeamento && (
              <>
                <h5>
                  2. Questionário preliminar:{" "}
                  {
                    moduloProcessos.areas?.find((a) => a._id === clickedArea)
                      ?.description
                  }
                </h5>
                <QuestionarioPreliminar
                  mapeamento={currentMapeamento}
                  area={clickedArea}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MapeamentoCliente;
