import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  YAxis,
  XAxis,
  ScatterChart,
  Scatter,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import { AnaliseRespostasTypes } from "../../../../../../../@types/analise";

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string | number;
}

interface TabelaProps {
  numeroColunas: number;
  numeroLinhas: number;
}

const Metricas: React.FC<AnaliseRespostasTypes> = ({
  activeCap,
  questId,
  versaoId,
}) => {
  const { moduloMapeamento, moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [porcentagemConforme, setPorcentagemConforme] = useState<any>(0);

  const [pontuacaoObtida, setPontuacaoObtida] = useState<any>(0);
  const [pontuacaoMaxima, setPontuacaoMaxima] = useState<any>(0);

  const [matrizRisco, setMatrizRisco] = useState<any>(null);

  const [questionario, setQuestionario] = useState<any>([]);

  function encontrarIndicePorValor(valorProcurado: any) {
    const capitulosQuestionario = questionario.capitulos?.map(
      (c: any) => c._id
    );

    // Contador global de índice
    let contadorGlobal = 0;

    const indicesPorCapituloEncontrados = capitulosQuestionario.map(
      (capId: string) => {
        const indicesEncontrados = questionario.perguntasRelacao
          ?.filter(
            (r: any) => r.pergunta?.description && r?.capitulo_id === capId
          )
          ?.sort((a: any, b: any) => {
            // Se ambos 'ordem' estão definidos, ordena por 'ordem'
            if (a.pergunta.ordem && b.pergunta.ordem) {
              return a.pergunta.ordem - b.pergunta.ordem;
            }

            // Se 'ordem' não está definido, ordena por 'created_at'
            return (
              new Date(a.pergunta.created_at).getTime() -
              new Date(b.pergunta.created_at).getTime()
            );
          })
          ?.map((objeto: any, indice: number) => {
            contadorGlobal++; // Incrementa o contador global

            if (objeto === valorProcurado) {
              return contadorGlobal;
            }
            return null;
          });

        return indicesEncontrados;
      }
    );

    const indicesValidos = indicesPorCapituloEncontrados
      ?.flat()
      .filter((indice: number) => indice !== null);

    return indicesValidos;
  }

  useEffect(() => {
    async function fetchData() {
      if (moduloMapeamento.questionarios && moduloProcessos.respostasAuth) {
        try {
          setQuestionario(
            moduloMapeamento.questionarios.filter(
              (q: any) => q._id.toString() === questId
            )[0]
          );

          let respostas = moduloProcessos.respostasAuth.map((resposta: any) => {
            return resposta.value.map(
              (value: any) =>
                resposta.pergunta.opcoes.find((opt: any) =>
                  resposta.value.includes(opt.alternativa)
                )?.grau
            );
          });
          respostas = respostas
            .flat()
            .filter((item: any) => item !== undefined);

          setPontuacaoObtida(
            respostas.reduce(
              (accumulator: number, currentValue: number) =>
                accumulator + currentValue,
              0
            )
          );

          const calcularPontuacaoMaxima = (perguntasRelacao: any) => {
            return perguntasRelacao
              ?.filter((pRel: any) => pRel.pergunta?.opcoes?.[0])
              ?.reduce((soma: number, pergRel: any) => {
                // Encontra a pontuação máxima entre as opções da pergunta
                let maxGrau = Math.max(
                  ...pergRel?.pergunta?.opcoes?.map(
                    (opcao: any) => opcao.grau || 0
                  )
                );

                return soma + maxGrau;
              }, 0);
          };

          setPontuacaoMaxima(
            calcularPontuacaoMaxima(
              moduloMapeamento.questionarios?.find((q) =>
                q?.versoes?.find((v: any) => v?._id === versaoId)
              )?.perguntasRelacao
            )
          );

          setPorcentagemConforme((pontuacaoObtida * 100) / pontuacaoMaxima);

          // definir matriz de risco
          const probabilidadeImpacto = moduloProcessos.respostasAuth.map(
            (resposta: any) => {
              return resposta.value.map((value: any) => {
                return {
                  probabilidade: resposta.pergunta.opcoes.find((opt: any) =>
                    resposta.value.includes(opt.alternativa)
                  )?.probabilidade,
                  impacto: resposta.pergunta.opcoes.find((opt: any) =>
                    resposta.value.includes(opt.alternativa)
                  )?.impacto,
                  risco:
                    (resposta.pergunta.opcoes.find((opt: any) =>
                      resposta.value.includes(opt.alternativa)
                    )?.impacto *
                      resposta.pergunta.opcoes.find((opt: any) =>
                        resposta.value.includes(opt.alternativa)
                      )?.probabilidade) /
                    9,
                  ...resposta,
                };
              });
            }
          );

          setMatrizRisco(probabilidadeImpacto.flat());
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [moduloMapeamento.questionarios, moduloProcessos.respostasAuth]);

  useEffect(() => {
    versaoId && moduloProcessos.getRespostasAuth(versaoId);
  }, [versaoId]);

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      const { probabilidade, impacto, risco } = payload[0].payload;

      return (
        <div
          style={{
            background: "#fff",
            border: "0.5px solid #00000033",
            padding: "1rem",
          }}
        >
          <p className="label">
            Probabilidade: <b>{probabilidade}</b>
          </p>
          <p className="label">
            Impacto: <b>{impacto}</b>
          </p>
          <p className="label">
            Risco: <b>{(risco * 100).toFixed(2)}</b>%
          </p>
          <p className="label">
            Perguntas:{" "}
            <b>
              {matrizRisco
                ?.filter((item: any) => item.risco === risco)
                ?.filter(
                  (item: any, index: number, self: any) =>
                    index ===
                    self.findIndex(
                      (i: any) => i.pergunta._id === item.pergunta._id
                    )
                )
                ?.map((item: any) =>
                  encontrarIndicePorValor(
                    questionario.perguntasRelacao.find(
                      (relPergun: any) =>
                        relPergun.pergunta._id === item.pergunta._id
                    )
                  )
                )
                .join(", ")}
            </b>
          </p>
          {/* {questionario.perguntasRelacao
                ?.filter(
                  (r: any) =>
                    r.capitulo_id === capitulo_id && r.pergunta?.description
                )
                .sort(
                  (a: any, b: any) =>
                    new Date(a.pergunta.created_at).getTime() -
                    new Date(b.pergunta.created_at).getTime()
                )
                .findIndex((r: any) => r.pergunta._id === pergunta._id) + 1} */}
          {/* <p>{pergunta.description}</p> */}
        </div>
      );
    }

    return null;
  };

  const GridMatrizrisco: React.FC<TabelaProps> = ({
    numeroColunas,
    numeroLinhas,
  }) => {
    // Função para verificar se a célula deve ter background verde
    const shouldHaveGreenBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background verde
      const celulasVerdes = [
        { linha: 2, coluna: 1 },
        { linha: 3, coluna: 1 },
        { linha: 3, coluna: 2 },
        { linha: 4, coluna: 1 },
        { linha: 4, coluna: 2 },
        { linha: 4, coluna: 3 },
      ];
      return celulasVerdes.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para verificar se a célula deve ter background laranja
    const shouldHaveOrangeBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background laranja
      const celulasLaranjas = [
        { linha: 1, coluna: 1 },
        { linha: 2, coluna: 2 },
        { linha: 3, coluna: 3 },
        { linha: 4, coluna: 4 },
      ];
      return celulasLaranjas.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para criar as células da tabela
    const renderCelulas = () => {
      let celulas: JSX.Element[] = [];
      for (let i = 0; i < numeroLinhas; i++) {
        let linha: JSX.Element[] = [];

        for (let j = 0; j < numeroColunas; j++) {
          const isCelulaVerde = shouldHaveGreenBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index
          const isCelulaLaranja = shouldHaveOrangeBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index

          linha.push(
            <div
              key={`celula-${i}-${j}`}
              className="celula"
              style={{
                backgroundColor: isCelulaVerde
                  ? "#33CCFF"
                  : isCelulaLaranja
                  ? "#4C72E3"
                  : "#5F29CC",
              }}
            ></div>
          );
        }
        celulas.push(
          <div key={`linha-${i}`} className="linha">
            {linha}
          </div>
        );
      }
      return celulas;
    };

    return <div className="tabela">{renderCelulas()}</div>;
  };

  // Função para formatar os ticks e exibir apenas inteiros nos rótulos dos eixos
  const formatTick = (tick: any) => {
    if (tick % 1 === 0) {
      // Exibir apenas inteiros
      return tick.toString();
    }
    return ""; // Ocultar valores fracionários
  };

  const CustomizedShape = (props: any) => {
    const { cx, cy, fill, payload } = props;
    const { risco } = payload;

    const itensMatriz = matrizRisco
      ?.filter((item: any) => item.risco === risco)
      ?.filter(
        (item: any, index: number, self: any) =>
          index ===
          self.findIndex((i: any) => i.pergunta._id === item.pergunta._id)
      )
      ?.map((item: any) =>
        encontrarIndicePorValor(
          questionario.perguntasRelacao.find(
            (relPergun: any) => relPergun.pergunta._id === item.pergunta._id
          )
        )
      );
    return (
      <g>
        {/* <Dot cx={cx} cy={cy} r={5} width={3} height={3} fill={fill} /> */}
        <g transform={`translate(${cx},${cy})`}>
          <text x={0} y={5} dy={0} textAnchor="middle" fontSize={10}>
            {itensMatriz.length > 7 ? (
              <>
                <tspan x={0} y={-5} dy={0}>
                  {itensMatriz
                    .slice(0, Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                  ,
                </tspan>
                <tspan x={0} y={10} dy={0}>
                  {itensMatriz
                    .slice(Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                </tspan>
              </>
            ) : (
              itensMatriz.join(", ")
            )}
          </text>
        </g>
      </g>
    );
  };

  return (
    <div className="analise__metrics" translate="no">
      <div
        style={{
          width: 50,
          height: 200,
          marginRight: "4rem",
          background:
            "rgb(0,255,8) linear-gradient(180deg, #33CCFF 0%, #4C72E3 50%,  #5F29CC 100%)",
        }}
      >
        <p
          style={{
            position: "relative",
            top: 110 + "px",
            marginTop: "-29px",
            left: "78px",
            color:
              porcentagemConforme > 33 && porcentagemConforme < 66
                ? "#5F29CC"
                : porcentagemConforme >= 66
                ? "#33CCFF"
                : "#4C72E3",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          {porcentagemConforme >= 66 && "ALTA"}
          {porcentagemConforme > 33 && porcentagemConforme < 66 && "MÉDIA"}
          {porcentagemConforme <= 33 && "BAIXA"}
          <p
            style={{
              position: "absolute",
              fontSize: "0.8rem",
              width: "6rem",
              marginLeft: "-0.4rem",
              marginTop: "-0.4rem",
            }}
          >
            {porcentagemConforme.toFixed(2)} pontos
          </p>
        </p>
        <p
          style={{
            marginTop: "-21px",
            marginLeft: "-13px",
          }}
        >
          Maturidade
        </p>
        <div
          style={{
            width: "70px",
            height: "2px",
            marginTop: "-2px",
            background: "#000",
            opacity: 0.3,
            marginLeft: "-10px",
          }}
        />
        <div
          style={{
            width: "70px",
            height: "2px",
            background: "#000",
            marginLeft: "-10px",
            marginTop: `calc(${100 - porcentagemConforme}px*2)`,
          }}
        >
          <div className="arrow right" style={{ top: "-3.5px" }} />
          <div
            className="arrow left"
            style={{ top: "-12.9px", left: "63px" }}
          />
        </div>
        {/* <div
          style={{
            width: "70px",
            height: "2px",
            background: "#000",
            opacity: 0.3,
            marginLeft: "-10px",
            marginTop: `calc(196px - calc(${porcentagemConforme}px*2))`,
          }}
        /> */}
      </div>
      <div
        style={{
          position: "relative",
          width: "600px",
          height: "300px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
            pointerEvents: "none",
            width: "100%",
          }}
        >
          <GridMatrizrisco numeroColunas={4} numeroLinhas={4} />
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis
              label={{
                value: "Probabilidade",
                position: "bottom",
                offset: 0,
              }}
              type="number"
              dataKey="probabilidade"
              name="Probabilidade"
              ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
              domain={[0, 3]}
              tickFormatter={formatTick}
            />
            <YAxis
              label={{
                value: "Impacto",
                angle: -90,
                position: "left",
              }}
              type="number"
              dataKey="impacto"
              ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
              domain={[0, 3]}
              name="Impacto"
              tickFormatter={formatTick}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ strokeDasharray: "3 3" }}
            />
            {/* <CartesianGrid stroke="#000" strokeWidth={0.5} /> */}
            <Scatter
              fill="#000"
              name="Matriz de Risco"
              data={matrizRisco}
              shape={<CustomizedShape />}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>

      {/* <PieChart width={290} height={175}>
        <Pie
          dataKey="value"
          data={dataAll}
          innerRadius={25}
          outerRadius={50}
          label
        />
        <Legend align="right" verticalAlign="middle" layout="vertical" />
        <Tooltip />
      </PieChart> */}
      {/* <BarChart
        width={550}
        height={200}
        data={dataBar.filter(
          (data: any) => data.Inconformidades + data.Conformidades !== 0
        )}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis />
        <XAxis
          dataKey="categoria"
          axisLine={true}
          tickLine={false}
          interval={0}
          height={1}
        />
        <Tooltip />
        <Bar dataKey="Conformidades" fill="#43CB03" />
        <Bar dataKey="Inconformidades" fill="#FE0C0C" />
      </BarChart> */}
      {/* <BarChart
        width={550}
        height={200}
        data={dataBar.filter(
          (data: any) => data.Inconformidades + data.Conformidades !== 0
        )}
        layout="vertical"
        barCategoryGap={1}
        margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis hide />
        <YAxis type="category" dataKey="categoria" width={150} />
        <Tooltip />
        <Bar dataKey="Inconformidades" fill="#5F29CC" />
        <Bar dataKey="Conformidades" fill="#33CCFF" />
      </BarChart> */}
      {/* <RadarChart height={500} width={500} outerRadius="80%" data={dataBar}>
        <PolarGrid />
        <PolarAngleAxis dataKey="categoria" />
        <PolarRadiusAxis />
        <Radar
          dataKey="Inconformidades"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
      </RadarChart> */}
    </div>
  );
};

export default Metricas;
