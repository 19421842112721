import React, { useEffect, useState } from "react";

import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { useParams } from "react-router-dom";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import OWASP from "../OWASP/OWASP";

const OwaspCliente = () => {
  const { getAnexosOwasp } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const params = useParams();

  const [importedData, setImportedData] = useState<any>(null);
  const [currentRelatorio, setCurrentRelatorio] = useState<any>(null);
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    params.id && getAnexosOwasp(params.id);
  }, [params.id]);

  const handleImport = async (file: any) => {
    try {
      setFile(file);
      const fileContent: any = await readFile(file);
      const jsonData = JSON.parse(fileContent);
      setImportedData(jsonData);
    } catch (error) {
      console.error("Erro ao processar o arquivo JSON:", error);
    }
  };

  const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        resolve(e.target.result);
      };

      reader.onerror = (e: any) => {
        reject(e.target.error);
      };

      reader.readAsText(file);
    });
  };

  return (
    <div>
      <BackHeader title="Análise do site" />
      <OWASP />
    </div>
  );
};

export default OwaspCliente;
