import { useContext, useEffect, useState } from "react";
import api from "../services/api";
import { GlobalContext } from "./globalContext";
import { message } from "antd";
import { useProcessosContext } from "./processosContext";
import { useNavigate } from "react-router-dom";
import { Titular } from "../@types/titular";

export interface Questionario {
  _id?: string;
  cliente_id?: string;
  nome?: string;
  areas?: string[];
  inativo?: boolean;
  versoes?: any;
  qtd_respondentes?: any;
  capitulos?: any;
  perguntasRelacao?: any;
  abordagem: "detalhada" | "simplificada" | undefined | null;
  created_at: string;
  updated_at: string;
}

export type MapeamentoContextType = {
  sendQuestionarios: (v: any) => void;
  setQuestionarios: (v: any) => void;
  getQuestionarios: () => void;
  getQuestionario: (quest_id: string, user_id: string) => void;
  getQuestionarioRespondente: (
    tokenUrl: string,
    loginToken: string
  ) => Promise<any>;
  getTemplates: () => void;
  postImportTemplate: (json: any) => Promise<any>;
  saveQuestionario: (
    client_id: string | null | undefined,
    questionarios: any
  ) => Promise<any>;
  questionarios: Questionario[];
  templates: any[];
  importedTemplate: any | null;
  delegarQuestionario: (values: any) => void;
  setCurrentTitulares: (titular: Titular[] | any) => void;
  currentTitulares: Titular[] | null;
  updateQuestionarioAreas: (questionarioId: string, areas: string[]) => void;
  saveDados: (
    departamento: string,
    categoria: string,
    nome: string,
    description: string
  ) => void;
  getDados: () => void;
  dados: any;
  getTitulares: () => void;
  getFicha: () => void;
  saveTitulares: (titulares: any[] | null) => Promise<any>;
  getQuestionariosCliente: (cliente_id: string) => Promise<any>;
  ficha: any;
  currentUserHasGestorPermission: boolean;
  setCurrentUserHasGestorPermission: (permission: boolean) => void;
  currentUserHasLiderPermission: boolean;
  setCurrentUserHasLiderPermission: (permission: boolean) => void;
  activeMapeamentoTab: string;
  setActiveMapeamentoTab: (tab: string) => void;
  currentMapeamento: Questionario | null | undefined;
  setCurrentMapeamento: (q: Questionario | null | undefined) => void;
};

export const useMapeamentoContext = (): MapeamentoContextType => {
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const processosContext = useProcessosContext();

  const [questionarios, setQuestionarios] = useState<any[]>([]);
  const [templates, setTemplates] = useState<any[]>([]);
  const [importedTemplate, setImportedTemplate] = useState<any | null>(null);
  const [dados, setDados] = useState<any | null>(null);
  const [currentTitulares, setCurrentTitulares] = useState<Titular[] | any>(
    null
  );
  const [ficha, setFicha] = useState<Titular[] | any>(null);
  const [currentUserHasGestorPermission, setCurrentUserHasGestorPermission] =
    useState<boolean>(false);
  const [currentUserHasLiderPermission, setCurrentUserHasLiderPermission] =
    useState<boolean>(false);
  const [activeMapeamentoTab, setActiveMapeamentoTab] = useState<string>("1");
  const [currentMapeamento, setCurrentMapeamento] = useState<
    Questionario | null | undefined
  >(null);

  useEffect(() => {
    if (!currentMapeamento && questionarios?.[0]) {
      const mapeamentos = questionarios?.filter(
        (item) => item.nome === "Assessment Process"
      );

      if (mapeamentos && mapeamentos.length === 1) {
        const firstMapeamento = mapeamentos[0];

        firstMapeamento && setCurrentMapeamento(firstMapeamento);
      }
    }
  }, [questionarios]);

  // Função para obter todos os dados
  const getDados = () => {
    globalContext?.incrementLoadingCount();
    api
      .get("/dados")
      .then((response) => {
        setDados(response.data);
      })
      .catch((err) => {
        console.error("Erro ao buscar dados:", err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  // Função para obter ficha de processo (mapeamento/questionario)
  const getFicha = () => {
    globalContext?.incrementLoadingCount();
    api
      .get("/ficha")
      .then((response) => {
        setFicha(response.data);
      })
      .catch((err) => {
        console.error("Erro ao buscar dados:", err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  // Função para salvar um novo dado
  const saveDados = (novoDado: any) => {
    globalContext?.incrementLoadingCount();
    api
      .post("/dados", novoDado)
      .then(() => {
        message.success("Novo dado cadastrado com sucesso!");
        getDados(); // Atualiza a lista de dados após o cadastro
      })
      .catch((err) => {
        message.error("Erro ao cadastrar novo dado.");
        console.error("Erro ao cadastrar novo dado:", err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  // Função para salvar titulares
  const getTitulares = () => {
    globalContext?.incrementLoadingCount();
    api
      .get("/titulares")
      .then((res) => {
        setCurrentTitulares(res.data);
      })
      .catch((err) => {})
      .finally(() => globalContext?.decrementLoadingCount());
  };

  // Função para salvar titulares
  const saveTitulares = (titulares: any[] | null): Promise<string> => {
    globalContext?.incrementLoadingCount();

    return new Promise((resolve, reject) => {
      api
        .post("/titulares", titulares === null ? currentTitulares : titulares)
        .then(() => {
          message.success("Titulares salvos com sucesso!");
          getTitulares(); // Atualiza a lista de titulares
          resolve("success"); // Retorna o status de sucesso
        })
        .catch((err) => {
          message.error("Erro ao salvar titulares.");
          reject("error"); // Retorna o status de erro
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const sendQuestionarios = (v: any) => {
    globalContext?.incrementLoadingCount();
    api
      .post("sendquestionarios", {
        versao_id: v._id,
      })
      .then((response) => {
        getQuestionariosCliente(
          globalContext?.currentCliente?._id ||
            globalContext?.userInfo?.cliente_id
        );
        message.success("Questionário enviado para respondentes com sucesso!");
      })
      .catch((err) => {
        message.error(
          "Falha ao enviar questionário para um ou mais respondente(s)!"
        );
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const getQuestionarios = () => {
    globalContext?.incrementLoadingCount();
    api
      .get("questionarios", {})
      .then((response) => {
        setQuestionarios(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const getQuestionariosCliente = (cliente_id: string) => {
    globalContext?.incrementLoadingCount();

    return new Promise<any>(async (resolve, reject) => {
      await api
        .get("questionarios/" + cliente_id)
        .then((response) => {
          setQuestionarios(response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const getQuestionarioRespondente = (tokenUrl: string, loginToken: string) => {
    globalContext?.incrementLoadingCount();
    return new Promise<any>(async (resolve, reject) => {
      await api
        .get(`questionariorespondente/${tokenUrl}`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const getTemplates = () => {
    globalContext?.incrementLoadingCount();
    api
      .get("templates", {})
      .then((response) => {
        setTemplates(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const postImportTemplate = (json: any) => {
    globalContext?.incrementLoadingCount();
    return new Promise<any>(async (resolve, reject) => {
      await api
        .post("importtemplate", json)
        .then((response) => {
          setImportedTemplate(response.data);
          message.success("Área salva com sucesso!");
          resolve(response);
          processosContext?.getAreas();
          processosContext?.getCapituloPerguntas();
          processosContext?.getPerguntas();
          processosContext?.getCapitulos();
        })
        .catch((err) => {
          message.error("Erro ao salvar área importada!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const getQuestionario = (quest_id: string, user_id: string) => {
    globalContext?.incrementLoadingCount();
    api
      .get("questionariorespondente", {
        params: {
          questionario_id: quest_id,
          user_id: user_id,
        },
      })
      .then((response) => {
        setQuestionarios(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const saveQuestionario = (
    client_id: string | null | undefined,
    questionarios: any
  ) => {
    globalContext?.incrementLoadingCount();
    return new Promise((resolve, reject) => {
      api
        .post("questionarios", {
          cliente_id: client_id,
          questionarios: questionarios,
        })
        .then(async () => {
          let response;
          if (client_id) {
            response = await getQuestionariosCliente(client_id);
          } else {
            response = await getQuestionariosCliente(
              globalContext?.currentCliente?._id ||
                globalContext?.userInfo?.cliente_id
            );
          }
          message.success("Mapeamento salvo com sucesso!");
          if (client_id === null) {
            navigate(-1);
          }
          resolve(response); // Resolve a Promise em caso de sucesso
        })
        .catch((err) => {
          message.error("Falha ao salvar mapeamento!");
          reject(err); // Rejeita a Promise em caso de erro
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const delegarQuestionario = (values: any) => {
    globalContext?.incrementLoadingCount();
    api
      .post("delegarquestionario", values)
      .then((response) => {
        globalContext?.getUsuarios();
        message.success("Respondentes associados com sucesso!");
      })
      .catch((err) => {
        message.error("Falha ao salvar delegação!");
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const updateQuestionarioAreas = (questionarioId: string, areas: string[]) => {
    globalContext?.incrementLoadingCount();
    api
      .put(`questionario/${questionarioId}`, { areas })
      .then((response) => {
        message.success("Áreas do questionário atualizadas com sucesso!");
        getQuestionariosCliente(
          globalContext?.currentCliente?._id ||
            globalContext?.userInfo?.cliente_id
        ); // Atualiza a lista de questionários após a atualização
      })
      .catch((err) => {
        message.error("Erro ao atualizar as áreas do questionário!");
        console.error(err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  return {
    sendQuestionarios,
    getQuestionario,
    getQuestionarios,
    setQuestionarios,
    getQuestionarioRespondente,
    getTemplates,
    saveQuestionario,
    postImportTemplate,
    questionarios,
    templates,
    importedTemplate,
    delegarQuestionario,
    setCurrentTitulares,
    currentTitulares,
    updateQuestionarioAreas,
    saveDados,
    getDados,
    dados,
    saveTitulares,
    getTitulares,
    getFicha,
    ficha,
    currentUserHasGestorPermission,
    setCurrentUserHasGestorPermission,
    activeMapeamentoTab,
    setActiveMapeamentoTab,
    currentUserHasLiderPermission,
    setCurrentUserHasLiderPermission,
    getQuestionariosCliente,
    setCurrentMapeamento,
    currentMapeamento,
  };
};
