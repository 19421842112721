import {
  Alert,
  Breadcrumb,
  Button,
  Collapse,
  message,
  Popconfirm,
  Spin,
  Tabs,
} from "antd";
import React, { ReactNode, useState } from "react";
import { GlobalContextType } from "../../../../../@types/global";
import { Titular } from "../../../../../@types/titular";
import { GlobalContext } from "../../../../../context/globalContext";
import { Questionario } from "../../../../../context/mapeamentoContext";
import { CapituloType } from "../../../../../context/processosContext";
import "./styles.css";
import BackHeader from "../../../../../components/BackHeader/BackHeader";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import ColetaGuia from "./components/ColetaGuia";
import ProcessamentoGuia from "./components/ProcessamentoGuia";
import CompartilhamentoGuia from "./components/CompartilhamentoGuia";
import ArmazenamentoGuia from "./components/ArmazenamentoGuia";

interface GuiasMapeamentoProps {
  processo: CapituloType;
  mapeamento: Questionario | null | undefined;
  setMapeamento: (v: any) => void;
  setProcesso: (v: any) => void;
}

const GuiasMapeamento: React.FC<GuiasMapeamentoProps> = ({
  processo,
  mapeamento,
  setProcesso,
}) => {
  const { clientes, moduloProcessos, moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [loadingTitulares, setLoadingTitulares] = useState(false);
  const [icon, setIcon] = useState<ReactNode>(<SaveOutlined />);
  const [activeTab, setActiveTab] = useState("Coleta");
  const [selectedDados, setSelectedDados] = useState<any[] | null>(null);

  const truncateDescription = (description: any, maxLength = 15) => {
    if (description?.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const handleDeleteTitular = (titular: Titular) => {
    // deixar titular inativo
    moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
      let currentTitular = titular;
      currentTitular.inativo = true;

      return [
        ...prevTitulares.filter((t) => t.key !== titular.key),
        currentTitular,
      ];
    });

    // salvar titulares atualizados
    moduloMapeamento
      .saveTitulares(moduloMapeamento.currentTitulares)
      .then(() => {
        message.success("Alterações salvas com sucesso!");
      })
      .catch(() => {
        message.success("Erro ao salvar alterações!");
      })
      .finally(() => setLoadingTitulares(false));
  };

  return (
    <div className="guias-mapeamento-resumo">
      <BackHeader title="Mapeamentos" prevAction={() => setProcesso(null)} />
      <Breadcrumb
        items={[
          {
            title: truncateDescription(
              clientes?.find((cli: any) => cli._id === mapeamento?.cliente_id)
                ?.razao_social,
              20
            ),
          },
          {
            title: truncateDescription(
              moduloProcessos.areas?.find(
                (a) =>
                  a._id ===
                  (processo.dependencia
                    ? mapeamento?.capitulos?.find(
                        (c: any) => c._id === processo.dependencia
                      )?.tags?.[0]
                    : processo.tags?.[0])
              )?.description,
              20
            ),
          },
          {
            title: truncateDescription(
              moduloProcessos?.capitulos?.find(
                (c) => c._id === processo.dependencia
              )?.description,
              20
            ),
          },
          {
            title: (
              <span style={{ color: "#5f29cc" }}>
                {truncateDescription(processo.description, 35)}
              </span>
            ),
          },
        ]}
      />
      <br />
      <h4>Titulares</h4>
      <br />
      {moduloMapeamento.currentTitulares ? (
        moduloMapeamento.currentTitulares
          .filter(
            (titular: Titular) =>
              titular.relacao_id ===
              (processo?.questionarioCapitulo?._id ||
                mapeamento?.capitulos?.find((p: any) => p._id === processo._id)
                  ?.questionarioCapitulo?._id)
          )
          ?.sort((a, b) => {
            const dateA =
              a.created_at instanceof Date && !isNaN(a.created_at.getTime())
                ? a.created_at.getTime()
                : -Infinity;
            const dateB =
              b.created_at instanceof Date && !isNaN(b.created_at.getTime())
                ? b.created_at.getTime()
                : -Infinity;

            if (dateA !== dateB) {
              return dateA - dateB; // Ordena pelas datas
            }

            // Se ambos não têm created_at, ordenar pela key
            return (a.key ?? 0) - (b.key ?? 0);
          })
          ?.map((titular: Titular, indexT: number) => {
            // Extrair todas as categorias em um array
            const categorias: any = moduloMapeamento.dados.map(
              (item: any) => item.categoria
            );

            // Usar um conjunto para remover valores duplicados
            const categoriasUnicasSet = new Set(categorias);

            // Converter o conjunto de volta para um array
            const categoriasUnicas = Array.from(categoriasUnicasSet);

            return (
              <Collapse
                style={{ marginTop: indexT === 0 ? 0 : "0.5rem" }}
                size="small"
                items={[
                  {
                    key: 1,
                    label: titular.categoria || "Novo titular",
                    extra: loadingTitulares ? (
                      <Spin size="small" />
                    ) : (
                      <Popconfirm
                        title="Você tem certeza que deseja excluir este item?"
                        onConfirm={() => handleDeleteTitular(titular)}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <DeleteOutlined
                          style={{
                            cursor: "pointer",
                            color: "red",
                          }}
                        />
                      </Popconfirm>
                    ),
                    children: (
                      <Tabs
                        defaultActiveKey={activeTab}
                        tabPosition={"left"}
                        activeKey={activeTab}
                        onTabClick={(v: string) => setActiveTab(v)}
                        style={{ height: 500 }}
                        items={[
                          {
                            label: `1. Coleta`,
                            key: "Coleta",
                            children: (
                              <ColetaGuia
                                categoriasUnicas={categoriasUnicas}
                                icon={icon}
                                setIcon={setIcon}
                                loadingTitulares={loadingTitulares}
                                setActiveTab={setActiveTab}
                                setLoadingTitulares={setLoadingTitulares}
                                activeTab={activeTab}
                                titular={titular}
                              />
                            ),
                          },
                          {
                            label: `2. Processamento`,
                            key: "Processamento",
                            children: (
                              <ProcessamentoGuia
                                icon={icon}
                                loadingTitulares={loadingTitulares}
                                selectedDados={selectedDados}
                                setActiveTab={setActiveTab}
                                setIcon={setIcon}
                                setLoadingTitulares={setLoadingTitulares}
                                setSelectedDados={setSelectedDados}
                                titular={titular}
                                activeTab={activeTab}
                              />
                            ),
                          },
                          {
                            label: `3. Compartilhamento`,
                            key: "Compartilhamento",
                            children: (
                              <CompartilhamentoGuia
                                icon={icon}
                                loadingTitulares={loadingTitulares}
                                mapeamento={mapeamento}
                                selectedDados={selectedDados}
                                setActiveTab={setActiveTab}
                                setIcon={setIcon}
                                setLoadingTitulares={setLoadingTitulares}
                                setSelectedDados={setSelectedDados}
                                activeTab={activeTab}
                                titular={titular}
                              />
                            ),
                          },
                          {
                            label: `4. Armazenamento`,
                            key: "Armazenamento",
                            children: (
                              <ArmazenamentoGuia
                                icon={icon}
                                loadingTitulares={loadingTitulares}
                                selectedDados={selectedDados}
                                setIcon={setIcon}
                                setLoadingTitulares={setLoadingTitulares}
                                setSelectedDados={setSelectedDados}
                                activeTab={activeTab}
                                titular={titular}
                              />
                            ),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
              />
            );
          })
      ) : (
        <Alert type="info" message="Nenhum titular cadastrado" showIcon />
      )}
      <br />
      <Button
        onClick={() => {
          moduloMapeamento.setCurrentTitulares(
            (prevTitulares: Titular[] | null) => {
              const newTitular: Titular = {
                relacao_id:
                  processo?.questionarioCapitulo?._id ||
                  mapeamento?.capitulos?.find(
                    (p: any) => p._id === processo._id
                  )?.questionarioCapitulo?._id,
                key: moduloMapeamento.currentTitulares?.length || 0,
              };

              if (prevTitulares === null) {
                return [newTitular];
              } else {
                return [...prevTitulares, newTitular];
              }
            }
          );
        }}
        type="dashed"
        icon={<PlusOutlined />}
        style={{
          width: "100%",
          color: "#5f29cc",
          borderColor: "#5f29cc",
        }}
      >
        Cadastrar titular
      </Button>
    </div>
  );
};

export default GuiasMapeamento;
