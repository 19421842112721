import React, { useEffect, useState } from "react";
import "./styles.css";
import Metricas from "./components/Metricas/Metricas";
import Respostas from "./components/Respostas/Respostas";
import { Collapse } from "antd";
import { AnaliseTypes } from "../../../../../@types/analise";
import Indice from "../../../../../components/Questionario/Indice/Indice";
import { GlobalContext } from "../../../../../context/globalContext";
import { GlobalContextType } from "../../../../../@types/global";
import PdfDownload from "../../../../../components/PdfDownload/PdfDownload";
import moment from "moment";
import "moment/locale/pt-br";

import RelatorioMaturidade from "./components/RelatorioMaturidade/RelatorioMaturidade";
import RelatorioRIPD from "./components/RelatorioRIPD/RelatorioRIPD";

moment.locale("pt-br");

const Analise: React.FC<AnaliseTypes> = ({ questId, versaoId }) => {
  const [activeCat, setActiveCat] = useState<string>("");
  const { moduloMapeamento, clientes, moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [porcentagemConforme, setPorcentagemConforme] = useState<any>(null);

  const [pontuacaoObtida, setPontuacaoObtida] = useState<any>(0);
  const [pontuacaoMaxima, setPontuacaoMaxima] = useState<any>(0);
  const [currentRespostas, setCurrentRespostas] = useState<any>([]);

  const [matrizRisco, setMatrizRisco] = useState<any>(null);
  const [questionario, setQuestionario] = useState<any>([]);

  const itemsCollapse = [
    {
      key: "1",
      label: "Métricas",
      children: (
        <Metricas activeCap={activeCat} questId={questId} versaoId={versaoId} />
      ),
    },
    {
      key: "2",
      label: "Respostas",
      children: (
        <Respostas
          activeCap={activeCat}
          questId={questId}
          versaoId={versaoId}
        />
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      if (moduloMapeamento.questionarios && moduloProcessos.respostasAuth) {
        try {
          setQuestionario(
            moduloMapeamento.questionarios.filter(
              (q: any) => q._id.toString() === questId
            )[0]
          );

          let respostas = moduloProcessos.respostasAuth.map((resposta: any) => {
            return resposta.value.map(
              (value: any) =>
                resposta.pergunta.opcoes.find((opt: any) =>
                  resposta.value.includes(opt.alternativa)
                )?.grau
            );
          });
          respostas = respostas
            .flat()
            .filter((item: any) => item !== undefined);

          setPontuacaoObtida(
            respostas.reduce(
              (accumulator: number, currentValue: number) =>
                accumulator + currentValue,
              0
            )
          );

          const calcularPontuacaoMaxima = (perguntasRelacao: any) => {
            return perguntasRelacao
              ?.filter((pRel: any) => pRel.pergunta?.opcoes?.[0])
              ?.reduce((soma: number, pergRel: any) => {
                // Encontra a pontuação máxima entre as opções da pergunta
                let maxGrau = Math.max(
                  ...pergRel?.pergunta?.opcoes?.map(
                    (opcao: any) => opcao.grau || 0
                  )
                );

                return soma + maxGrau;
              }, 0);
          };

          setPontuacaoMaxima(
            calcularPontuacaoMaxima(
              moduloMapeamento.questionarios?.find((q) =>
                q?.versoes?.find((v: any) => v?._id === versaoId)
              )?.perguntasRelacao
            )
          );

          setPorcentagemConforme((pontuacaoObtida * 100) / pontuacaoMaxima);

          // definir matriz de risco
          const probabilidadeImpacto = moduloProcessos.respostasAuth.map(
            (resposta: any) => {
              return resposta.value.map((value: any) => {
                return {
                  probabilidade: resposta.pergunta.opcoes.find((opt: any) =>
                    resposta.value.includes(opt.alternativa)
                  )?.probabilidade,
                  impacto: resposta.pergunta.opcoes.find((opt: any) =>
                    resposta.value.includes(opt.alternativa)
                  )?.impacto,
                  risco:
                    (resposta.pergunta.opcoes.find((opt: any) =>
                      resposta.value.includes(opt.alternativa)
                    )?.impacto *
                      resposta.pergunta.opcoes.find((opt: any) =>
                        resposta.value.includes(opt.alternativa)
                      )?.probabilidade) /
                    9,
                  ...resposta,
                };
              });
            }
          );

          setMatrizRisco(probabilidadeImpacto.flat());

          const respostasPerguntas = moduloProcessos.respostasAuth.map(
            (resposta: any) => {
              return {
                ...resposta,
                pergunta: resposta.pergunta,
                respostas: resposta.value,
                graus: resposta.pergunta.opcoes.filter((opt: any) =>
                  resposta.value.includes(opt.alternativa)
                ),
              };
            }
          );
          setCurrentRespostas(respostasPerguntas);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [moduloMapeamento.questionarios, moduloProcessos.respostasAuth]);

  useEffect(() => {
    versaoId && moduloProcessos.getRespostasAuth(versaoId);
  }, [versaoId]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginBottom: "1rem",
        }}
        translate="no"
      >
        {moduloMapeamento.questionarios
          ?.find((q) => q._id === questId)
          ?.versoes?.find((v: any) => v._id === versaoId) &&
          clientes?.[0] &&
          pontuacaoMaxima !== 0 &&
          matrizRisco?.[0] &&
          typeof porcentagemConforme === "number" &&
          porcentagemConforme !== null &&
          !isNaN(porcentagemConforme) &&
          questionario && (
            <>
              {moduloMapeamento.questionarios
                ?.find((q) => q._id === questId)
                ?.nome?.toUpperCase()
                .includes("RIPD") ? (
                <PdfDownload
                  title={"Imprimir Análise"}
                  content={
                    <RelatorioRIPD
                      questId={questId!}
                      versaoId={versaoId!}
                      pontuacaoMaxima={pontuacaoMaxima}
                      porcentagemConforme={porcentagemConforme}
                      matrizRisco={matrizRisco}
                      questionario={questionario}
                      currentRespostas={currentRespostas}
                    />
                  }
                />
              ) : (
                <PdfDownload
                  title={"Imprimir Análise"}
                  content={
                    <RelatorioMaturidade
                      questId={questId!}
                      versaoId={versaoId!}
                      pontuacaoMaxima={pontuacaoMaxima}
                      porcentagemConforme={porcentagemConforme}
                      matrizRisco={matrizRisco}
                      questionario={questionario}
                      currentRespostas={currentRespostas}
                    />
                  }
                />
              )}
            </>
          )}
      </div>
      <div
        className="analise__multipla"
        style={{ padding: "0px !important" }}
        translate="no"
      >
        <Indice
          activeCat={activeCat}
          setActiveCat={setActiveCat}
          questionario={
            moduloMapeamento.questionarios?.filter(
              (q: any) => q._id.toString() === questId
            )[0]
          }
        />
        <div className="analise__content" translate="no">
          <Collapse items={itemsCollapse} defaultActiveKey={["1", "2"]} />
        </div>
      </div>
    </>
  );
};

export default Analise;
